import React, { Component } from "react";
import { connect } from "react-redux";
import { Layout, Button } from "antd";
import { Debounce } from "react-throttle";
import WindowResizeListener from "react-window-size-listener";
import { ThemeProvider } from "styled-components";
import authAction from "../../redux/auth/actions";
import appActions from "../../redux/app/actions";
// import Sidebar from '../Sidebar/Sidebar';
import Topbar from "../Topbar/Topbar";
import AppRouter from "./AppRouter";
import { siteConfig } from "../../settings";
import themes from "../../settings/themes";
import { themeConfig } from "../../settings";
import AppHolder from "./commonStyle";
import "./global.css";
import LinkedIn from "../../image/icon/Linkedin.png";
import ContactBotton from "../Contact/index";
import { Fragment } from "react";
import WebTour from "./../../webTour";

const { Content, Footer } = Layout;
const { logout } = authAction;
const { toggleAll } = appActions;
export class App extends Component {
  redirectUser() {
    localStorage.setItem("profile", 1);
  }

  render() {
    const { url } = this.props.match;
    const { height } = this.props;
    const appHeight = window.innerHeight;

    const isSuperUser = localStorage.getItem("role_id") == 12 ? true : false;
    const isManager = localStorage.getItem("is_manager") == 1 ? true : false;

    return (
      <ThemeProvider theme={themes[themeConfig.theme]}>

        <AppHolder>
          <Layout style={{ height: appHeight }}>
            <Debounce time="1000" handler="onResize">
              <WindowResizeListener
                onResize={(windowSize) =>
                  this.props.toggleAll(
                    windowSize.windowWidth,
                    windowSize.windowHeight
                  )
                }
              />
            </Debounce>
            <Topbar url={url} />
            <Layout style={{ flexDirection: "row", overflowX: "hidden" }}>
              {/* <Sidebar url={url} /> */}
              <Layout
                className="isoContentMainLayout"
                style={{
                  height: height,
                }}
              >
                <Content
                  className="isomorphicContent"
                  style={{
                    padding: "50px 0 0",
                    flexShrink: "0",
                    position: "relative",
                  }}
                >
                  <div
                    style={{
                      position: "fixed",
                      top: "50%",
                      transform: "translate(-50%)",
                      right: "-95px",
                    }}
                  >
                    <ContactBotton buttonName="Contact Us" />
                  </div>
                  <AppRouter url={url} />

                </Content>

                {(isSuperUser || isManager )  && (
                  <div style={{ textAlign: "right" }}>
                    <a
                      href={isSuperUser ? '/super_user' : '/manager' }
                      target="_self"
                      rel="noopener noreferrer"
                    >
                      <span
                        style={{
                          fontSize: "30px",
                          color: "rgb(87, 36, 126)",
                          paddingRight: "35px",
                        }}
                      >
                        {/* <i className="ion-navicon-round global_navigation"></i> */}
                        <i
                          className="global_navigation"
                          onClick={this.redirectUser}
                        >
                          <svg
                            aria-hidden="true"
                            fill="currentColor"
                            height="1em"
                            width="1em"
                            data-icon="appstore"
                            focusable="false"
                            viewBox="64 64 896 896"
                          >
                            <path d="M464 144H160c-8.8 0-16 7.2-16 16v304c0 8.8 7.2 16 16 16h304c8.8 0 16-7.2 16-16V160c0-8.8-7.2-16-16-16zm-52 268H212V212h200v200zm452-268H560c-8.8 0-16 7.2-16 16v304c0 8.8 7.2 16 16 16h304c8.8 0 16-7.2 16-16V160c0-8.8-7.2-16-16-16zm-52 268H612V212h200v200zM464 544H160c-8.8 0-16 7.2-16 16v304c0 8.8 7.2 16 16 16h304c8.8 0 16-7.2 16-16V560c0-8.8-7.2-16-16-16zm-52 268H212V612h200v200zm452-268H560c-8.8 0-16 7.2-16 16v304c0 8.8 7.2 16 16 16h304c8.8 0 16-7.2 16-16V560c0-8.8-7.2-16-16-16zm-52 268H612V612h200v200z"></path>
                          </svg>
                        </i>
                      </span>
                    </a>
                  </div>
                )}

                <Footer
                  style={{
                    background: "#ffffff",
                    textAlign: "center",
                    borderTop: "1px solid #ededed",
                  }}
                >
                  {/* <i className='ion-social-linkedin '></i> */}
                  <a
                    href="https://www.facebook.com/majesticservicesgroup"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <span
                      className="isoLeft"
                      style={{
                        fontSize: "20px",
                        color: "rgb(87, 36, 126)",
                      }}
                    >
                      <i className="ion-social-facebook"></i>
                    </span>
                  </a>{" "}
                  <a
                    href="https://www.linkedin.com/company/majesticservicesgroup"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <span
                      className="isoLeft"
                      style={{
                        fontSize: "20px",
                        color: "rgb(87, 36, 126)",
                        paddingLeft: "10px",
                      }}
                    >
                      <img
                        src={LinkedIn}
                        style={{ height: "17px", paddingBottom: "2px" }}
                      ></img>
                    </span>
                  </a>{" "}
                  <a
                    href="https://twitter.com/majesticservgrp"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <span
                      className="isoLeft"
                      style={{
                        fontSize: "20px",
                        color: "rgb(87, 36, 126)",
                        paddingLeft: "10px",
                      }}
                    >
                      <i className="ion-social-twitter"></i>
                    </span>
                  </a>
                  <br /> {siteConfig.footerText}
                </Footer>
              </Layout>
            </Layout>
            {/* <WebTour /> */}
          </Layout>
        </AppHolder>
      </ThemeProvider>
    );
  }
}

export default connect(
  (state) => ({
    auth: state.Auth,
    height: state.App.height,
  }),
  { logout, toggleAll }
)(App);
