import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import Tour from "reactour";
import { SetWebTourAction } from "./actions/actions";

const stepStyle ={
  transform:"translate(500px,6px)"
}

const steps = [
  {
    selector: ".first-step",
    content: () => (
      <div>
        <h3>Group / Facility </h3>
        <div>You can toggle between group and facility.</div>
        <div>Dashboard displays data at group level or</div>
        <div>facility level</div>
      </div>
    ),
    style:stepStyle
  },
  // {
  //   selector: ".second-step",
  //   content: () => (
  //     <div>
  //       <h3>Facility</h3>
  //       <div>This is first step - Facility</div>
  //     </div>
  //   ),
  // },

  {
    selector: ".third-step",
    content: () => (
      <div>
        <h3>Month</h3>
        <div>Display cleaning audit and laundry audit</div>
        <div> data for selected month</div>
      </div>
    ),
  },
  {
    selector: ".fourth-step",
    content: () => (
      <div>
        <h2>Reports</h2>
        <div>Display varoius reports</div>
        <div> i.e. Cleaning audit, Laundry audit, KPI,</div>
        <div> Equipment, Surveys, Maintenance,</div>
        <div> Housekeeping, Education and Training</div>
      </div>
    ),
  },
  {
    selector: ".fifth-step",
    content: () => (
      <div>
        <h2>Certificates</h2>
        <div>Insurance certificates e.g. Public liability,</div>
        <div>Workcover, ISO, CM3 certificate</div>
      </div>
    ),
  },
  {
    selector: ".sixth-step",
    content: () => (
      <div>
        <h2>Profile</h2>
        <div>User can view details and</div>
        <div> change password</div>
      </div>
    ),
  },
  {
    selector: ".seventh-step",
    content: () => (
      <div>
        <h2>Contact Us</h2>
        <div>Use this section to contact us</div>
        <div>in case of any queries or help</div>
        <div>with the portal</div>
      </div>
    ),
  },
  {
    selector: ".ninegth-step",
    content: () => (
     
       <div>
        <h2>Web-help</h2>
        <div>Please click here to re-launch web-help </div>
      </div>
    ),
  },
];

const steps1 = [
  {
    selector: ".eighth-step",
    content: () => (
      <div>
        <h2>Dashboard</h2>
        <div>Display audit and education info for </div>
        <div>three month</div>
      </div>
    ),
  },
  {
    selector: ".fourth-step",
    content: () => (
      <div>
        <h2>Reports</h2>
        <div>Display varoius reports</div>
        <div> i.e. Cleaning audit, Laundry audit, KPI,</div>
        <div> Equipment, Surveys, Maintenance,</div>
        <div> Housekeeping, Education and Training</div>
      </div>
    ),
  },
  {
    selector: ".fifth-step",
    content: () => (
      <div>
        <h2>Certificates</h2>
        <div>Insurance certificates e.g. Public liability,</div>
        <div>Workcover, ISO, CM3 certificate</div>
      </div>
    ),
  },
  {
    selector: ".sixth-step",
    content: () => (
      <div>
        <h2>Profile</h2>
        <div>User can view details and</div>
        <div> change password</div>
      </div>
    ),
  },
  {
    selector: ".seventh-step",
    content: () => (
      <div>
        <h2>Contact Us</h2>
        <div>Use this section to contact us</div>
        <div>in case of any queries or help</div>
        <div>with the portal</div>
      </div>
    ),
  },
  {
    selector: ".ninegth-step",
    content: () => (
      <div>
        <h2>Web-help</h2>
        <div>Please click here to re-launch web-help </div>
      </div>
    ),
  },
];

function WebTour(props) {
  const [isTourOpen, setIsTourOpen] = useState(true);

  useEffect(() => {
    setIsTourOpen(props.set_web_tour);
  }, []);

  const tourClose = () => {
    const { dispatch } = props;
    dispatch(SetWebTourAction(false))
    localStorage.setItem("is_first_login", 'false')

    // setIsTourOpen(false);
    // if(props.passFunc){
    //   console.log("inside close toure");
    //   props.passFunc()
    // }
  };

  return (
    <>
      {localStorage.getItem("role_id") === "10" &&  localStorage.getItem("is_first_login") === "true" ? (
        <Tour
          steps={steps}
          isOpen={isTourOpen}
          rounded={5}
          stepInteraction={true}
          accentColor="#4a2584"
          onRequestClose={() => tourClose()}
        />
      ) : (
        ""
      )}
      {localStorage.getItem("role_id") === "11" &&  localStorage.getItem("is_first_login") === "true" ? (
        <Tour
          steps={steps1}
          isOpen={isTourOpen}
          rounded={5}
          stepInteraction={true}
          accentColor="#4a2584"
          onRequestClose={() => tourClose(false)}
        />
      ) : (
        ""
      )}
    </>
  );
}

function mapStateToProps(state) {
  const { Common } = state;

  const  { set_web_tour } = Common;

  return { set_web_tour };
}

export default connect(mapStateToProps)(WebTour);
