import logoColored from "../image/icon/majestic_logo_250.jpg";
import logoTransparent from "../image/icon/majestic_logo_250.png";
//const config = require('../config').config;
export default {
  //for local
  apiUrl: process.env.REACT_APP_API_URL,
  localApiUrl: process.env.REACT_APP_LOCAL_API_URL,
  apiUrl2: process.env.REACT_APP_MYM_API_URL,
  
};

const siteConfig = {
  siteName: "Majestic",
  siteIcon: logoColored,
  siteIconTransparent: logoTransparent,
  siteIconColored: logoColored,
  footerText: "Majestic ©2022",
};
const themeConfig = {
  topbar: "themedefault",
  sidebar: "themedefault",
  layout: "themedefault",
  theme: "themedefault",
};
const language = "english";

const jwtConfig = {
  fetchUrl: "http://localhost:9000/api/",
  secretKey: "secretKey",
};

export { siteConfig, language, themeConfig, jwtConfig };
