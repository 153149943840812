import { MYM_WEB } from '../constants/rbuConstants';
import { getAllRequiredHeaders } from '../utility';
import client from './axios';

// rbu = Request baseURL
export const getRequest = async ({ url, rbu = MYM_WEB, extraParameters = {} }) => {
	return new Promise( async (resolve, reject) => {
		try {
			const res = await client.get(`${url}`, {
				headers: { ...getAllRequiredHeaders({ url, rbu }) },
				...extraParameters,
			});
			const { data, status } = res;
			// if (status === 204) {
			// 	return {
			// 		response: {
			// 			success: false,
			// 			message: 'Something Went Wrong!',
			// 			data: [],
			// 		},
			// 		error: true,
			// 	};
			// }
			return resolve(res);
			return { response: data, error: false };
		} catch (error) {
			return { response: { isLogout: true }, error: true };
		}
	})
};

export const postRequest = async ({ url, payload = {}, rbu = MYM_WEB, extraParameters = {} }) => {
	return new Promise( async (resolve, reject) => {
		try {
			const res = await client.post(`${url}`, payload, {
				headers: { ...getAllRequiredHeaders({ url, rbu }) },
				...extraParameters,
			});
			const { data, status } = res;
			// if (status === 204) {
			// 	return {
			// 		response: {
			// 			success: false,
			// 			message: 'Something Went Wrong!',
			// 			data: [],
			// 		},
			// 		error: true,
			// 	};
			// }
			return resolve(res);
		} catch (error) {
			console.log('error :>> ', error);
			// return reject(error);
			// return { response: { isLogout: true }, error: true };
		}

	})
};

export const putRequest = async ({ url, payload = {}, rbu, extraParameters = {} }) => {
	try {
		const res = await client.put(`${url}`, payload, {
			headers: { ...getAllRequiredHeaders({ rbu }) },
			...extraParameters,
		});
		const { data, status } = res;
		if (status === 204) {
			return {
				response: {
					success: false,
					message: 'Something Went Wrong!',
					data: [],
				},
				error: true,
			};
		}
		return { response: data, error: false };
	} catch (error) {
		return { response: { isLogout: true }, error: true };
	}
};

export const patchRequest = async ({ url, payload = {}, rbu, extraParameters = {} }) => {
	try {
		const res = await client.patch(`${url}`, payload, {
			headers: { ...getAllRequiredHeaders({ rbu }) },
			...extraParameters,
		});
		const { data, status } = res;
		if (status === 204) {
			return {
				response: {
					success: false,
					message: 'Something Went Wrong!',
					data: [],
				},
				error: true,
			};
		}
		return { response: data, error: false };
	} catch (error) {
		return { response: { isLogout: true }, error: true };
	}
};

export const deleteRequest = async ({ url, rbu, extraParameters = {} }) => {
	try {
		const res = await client.delete(`${url}`, {
			headers: { ...getAllRequiredHeaders({ rbu }) },
			...extraParameters
		});
		const { data, status } = res;
		if (status === 204) {
			return {
				response: {
					success: false,
					message: 'Something Went Wrong!',
					data: [],
				},
				error: true,
			};
		}
		return { response: data, error: false };
	} catch (error) {
		return { response: { isLogout: true }, error: true };
	}
};
