import React from "react";
// import { Route, Redirect } from 'react-router-dom';
import { Route } from "react-router-dom";
import { ConnectedRouter } from "react-router-redux";
import { connect } from "react-redux";

import App from "./containers/App/App";
import asyncComponent from "./helpers/AsyncFunc";

const RestrictedRoute = ({ component: Component, isLoggedIn, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      isLoggedIn ? (
        <Component {...props} />
      ) : (
        // <Redirect
        //   to={{
        //     pathname: '/signin',
        //     state: { from: props.location }
        //   }}
        // />
        <Component {...props} />
      )
    }
  />
);
const PublicRoutes = ({ history, isLoggedIn }) => {
  return (
    <ConnectedRouter history={history}>
      <div>
        <Route
          exact
          path={"/"}
          component={asyncComponent(() => import("./containers/Page/signin"))}
        />
        <Route
          exact
          path={"/signin"}
          component={asyncComponent(() => import("./containers/Page/signin"))}
        />
        <Route
          exact
          path={"/forgot_password"}
          component={asyncComponent(() =>
            import("./containers/Page/forgot_password")
          )}
        />
        <Route
          exact
          path={"/super_user"}
          component={asyncComponent(() =>
            import("./containers/superUser/index")
          )}
        />
        <Route
          exact
          path={"/manager"}
          component={asyncComponent(() =>
            import("./containers/manager/index")
          )}
        />
        <Route
          exact
          path={"/reset_password/email=:email&reset_password_token=:password"}
          component={asyncComponent(() =>
            import("./containers/Page/reset_password")
          )}
        />
        <RestrictedRoute
          path="/dashboard"
          component={App}
          isLoggedIn={isLoggedIn}
        />
      </div>
    </ConnectedRouter>
  );
};

export default connect((state) => ({
  isLoggedIn: state.Auth.idToken !== null,
}))(PublicRoutes);
