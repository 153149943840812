import React, { Component } from 'react';
import { Popover,Form , Icon } from 'antd';
import { connect } from 'react-redux';
import IntlMessages from '../../components/utility/intlMessages';
import TopbarDropdownWrapper from './topbarDropdown.style';
import {
 withRouter
} from "react-router-dom";
const demoNotifications = [
  {
    id: 1,
    name: 'Executive Kpi report',
    href: 'executive_KPI_report',
    notification:
      'Add new kpi report'
  },
  {
    id: 2,
    name: 'Cleaning Audit Report',
    href: 'cleaning_audit',
    notification:
      'A National Book Award Finalist An Edgar Award Finalist A California Book Award Gold Medal Winner'
  },
  {
    id: 3,
    name: 'Laundry Audit Report',
    href: 'cleaning_audit',
    notification:
      'A National Book Award Finalist An Edgar Award Finalist A California Book Award Gold Medal Winner'
  },
  {
    id: 4,
    name: 'Facility Kpi Report',
    href: 'facility_KPI_reports',
    notification:
      'A National Book Award Finalist An Edgar Award Finalist A California Book Award Gold Medal Winner'
  }
];

class TopbarNotification extends Component {
  constructor(props) {
    super(props);
    this.handleVisibleChange = this.handleVisibleChange.bind(this);
    this.hide = this.hide.bind(this);
    this.state = {
      visible: false,
      notifications :demoNotifications
    };
  }
  hide() {
    this.setState({ visible: false });
  }
  handleVisibleChange() {
    this.setState({ visible: !this.state.visible });
  }

  NotificationClick(id)  {

    let notification = demoNotifications[id];
    let notifications = this.state.notifications;
    notifications.splice(id,1);
    this.setState({notifications,visible:false})
    this.props.history.push(notification.href);
  }

  CloseNotification() {
    this.setState({visible:false})
  }
  
  render() {
    // console.log(this.state,"syates ===========================");
    // const { customizedTheme } = this.props;
    const {notifications} = this.state;
    const content = (
      <TopbarDropdownWrapper className="topbarNotification">
        <div className="isoDropdownHeader">
          <h3>
            <IntlMessages id="sidebar.notification" />
          </h3>
        </div>
        <div className="isoDropdownBody">
          {notifications.map((notification,key) => (
            <span className="isoDropdownListItem" key={notification.id} onClick={()=>this.NotificationClick(key)}>
              <h5>{notification.name}  <span style={{position:'absolute',right:10}}><Icon type="close" onClick={()=>this.CloseNotification(key)} style={{fontSize:'20px',color:'red'}}/></span></h5> 
              {/* <p>{notification.notification}</p> */}
            </span>
          ))}
        </div>
        {/* <a className="isoViewAllBtn">
          <IntlMessages id="topbar.viewAll" />
        </a> */}
      </TopbarDropdownWrapper>
    );
    return (
      <Popover
        content={content}
        trigger="click"
        visible={this.state.visible}
        onVisibleChange={this.handleVisibleChange}
        placement="bottomLeft"
      >
        <div className="isoIconWrapper">
          <i
            className="ion-android-notifications"
            style={{ color: 'white' }}
          />
          <span style={{color:'black'}}>{demoNotifications.length}</span>
        </div>
      </Popover>
    );
  }
}

// export default TopbarNotification;

export default withRouter (connect(state => {
  // console.log(state,"router")
  return ({
    ...state.App,
    ...state.router,
    ...state.common,
    // customizedTheme: state.ThemeSwitcher.topbarTheme
  })
})(TopbarNotification));


