import React from "react";
import { connect } from "react-redux";
import { Menu, Icon } from "antd";
import { Link } from "react-router-dom";
import IntlMessages from "../../components/utility/intlMessages";
import appActions from "../../redux/app/actions";
import { options } from "./options";
import "./topnav.css";
// import { themeConfig } from '../../settings';
let options1 = options;
const { SubMenu } = Menu;

const {
  toggleOpenDrawer,
  changeOpenKeys,
  changeCurrent,
  toggleCollapsed,
} = appActions;
const stripTrailingSlash = (str) => {
  if (str.substr(-1) === "/") {
    return str.substr(0, str.length - 1);
  }
  return str;
};

class TopNav extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      org_id: localStorage.getItem("org_id"),
      menu_list: [],
    };
    this.handleClick = this.handleClick.bind(this);
    this.onOpenChange = this.onOpenChange.bind(this);
  }

  // handleClick = e => {
  //     console.log('click ', e);
  //     this.setState({
  //     current: e.key,
  //     });
  // };

  componentDidMount() {
    var Noptions = Array.from(new Set(options1));

    let org_id = localStorage.getItem("org_id");
    let role_id = localStorage.getItem("role_id");
    let login_role_id = localStorage.getItem("login_role_id");
    let superUser_org_id = localStorage.getItem("superUser_org_id");
    let is_manager = localStorage.getItem("is_manager");
    let fid = localStorage.getItem("facility_id");

    var self = this;

    let childrenForTSA = [
      {
        roleIds: [10, 12],
        key: "tsa-contract",
        label: "sidebar.tsa-contract"
      },
      {
        roleIds: [4, 10, 11, 12],
        key: "tsa_contact",
        label: "sidebar.tsa-contact"
      }
    ]
    
    // this.setState({
    //   menu_list : Noptions
    // })
    // myOptions().then(data => {
    //   this.setState({
    //     menu_list : data
    //   })
    //   console.log(data,"==============================")
    // })

    if (org_id === null) {
      setTimeout(function() {
        org_id = localStorage.getItem("org_id");
        let role_id = localStorage.getItem("role_id");
        let login_role_id = localStorage.getItem("login_role_id");
        let fid = localStorage.getItem("facility_id");
        org_id = parseInt(org_id);
        let newOption = Noptions.filter(function(x, i) {
          // if (
          //   org_id === 3 ||
          //   org_id === 5 ||
          //   org_id === 11 ||
          //   org_id === 15 ||
          //   org_id === 19 || org_id === 13
          // ) {
          //   if (x.children !== undefined && x.key === "report") {
          //     x.children[x.children.length] = {
          //       key: "KPI_inspection",
          //       label: "sidebar.KPI_inspection",
          //     };
          //   }
          // }
          if (org_id) {
            if (x.children !== undefined && x.key === "report") {
              x.children[x.children.length] = {
                key: "KPI_inspection",
                label: "sidebar.KPI_inspection",
              };
            }
          }
          if (org_id === 5) {
            if (x.children !== undefined && x.key === "report") {
              x.children[x.children.length] = {
                key: "cleaning_excellence",
                label: "sidebar.cleaning_excellence",
              };
            }
          }
          
          if (org_id === 1) {
            if (x.children !== undefined && x.key === "report") {
              x.children[x.children.length] = {
                  key: "cleaning excellence",
                  label: "sidebar.cleaning_excellence",
                  children: [
                      {
                          key: "daily_site_summary",
                          label: "sidebar.daily_site_summary",
                      },
                      {
                          key: "unable_to_clean",
                          label: "sidebar.unable_to_clean",
                      },
                      {
                          key: "maintenance",
                          label: "sidebar.maintenance",
                      },
                  ],
              };
            }
          }

          if (org_id === 15) {
            if (x.children !== undefined && x.key === "report") {
              x.children.push({
                key: "Surveys",
                label: "sidebar.Surveys",
                children: [
                  {
                    key: "cleaning_survey",
                    label: "sidebar.cleaning_survey",
                  },
                ],
              });
              x.children[x.children.length] = {
                key: "roll_out_plan",
                label: "sidebar.roll_out_plan",
              };
            }
          }

          if (org_id === 1 || org_id === 19) {
              if (x.children !== undefined && x.key === "report") {
                  x.children.push({
                      key: "Surveys",
                      label: "sidebar.Surveys",
                      children: [
                          {
                              key: "cleaning_survey",
                              label: "sidebar.cleaning_survey",
                          },
                          {
                              key: "laundry_survey",
                              label: "sidebar.laundry_survey",
                          },
                      ],
                  });
              }
          }

          if (org_id === 17) {
              if (x.children !== undefined && x.key === "report") {
                  x.children[x.children.length] = {
                      key: "equipment_maintenance",
                      label: "sidebar.equipment_maintenance",
                  };
                  x.children[x.children.length] = {
                      key: "key_swipe",
                      label: "sidebar.key_swipe",
                  };
                  x.children[x.children.length] = {
                      key: "landry_audit",
                      label: "sidebar.landry_audit",
                  };
                  x.children[x.children.length] = {
                      key: "KPI_inspection",
                      label: "sidebar.KPI_inspection",
                  };
              }
          }

          if (role_id == 12) {
              if (superUser_org_id == 13) {
                  if (x.children !== undefined && x.key === "report") {
                      x.children[x.children.length] = {
                          key: "air-purifier",
                          label: "sidebar.air_purifire",
                      };
                  }
              }
          } else if (role_id == 4) {
              if (is_manager == 1 && org_id == 13) {
                  if (x.children !== undefined && x.key === "report") {
                      x.children[x.children.length] = {
                          key: "air-purifier",
                          label: "sidebar.air_purifire",
                      };
                  }
              }
          } else if (org_id == 13) {
              if (x.children !== undefined && x.key === "report") {
                  x.children[x.children.length] = {
                      key: "air-purifier",
                      label: "sidebar.air_purifire",
                  };
              }
          } else {
          }

          if (org_id === 1 || org_id === 4 || org_id === 5 || org_id === 18) {
              if (x.children !== undefined && x.key === "report") {
                  x.children[x.children.length] = {
                      key: "landry_audit",
                      label: "sidebar.landry_audit",
                  };
              }
          }

          if (org_id === 16) {
            if (x.children !== undefined && x.key === "report") {
              // x.children.push({"key":"KPI_inspection" ,"label":"sidebar.KPI_inspection", children: [
              //   {
              //     key: 'executive_KPI_report',
              //     label: 'sidebar.executive_KPI_report',
              //   },
              //   {
              //     key: 'facility_KPI_reports',
              //     label: 'sidebar.facility_KPI_reports',
              //   }]});

              let meetingKey = x.children.findIndex(
                (data) => data.key === "meetings"
              );
              x.children[meetingKey] = {
                key: "meetings",
                label: "sidebar.meetings",
                children: [
                  {
                    key: "executive_meetings",
                    label: "sidebar.executive_meetings",
                  },
                  {
                    key: "facility_manager_meetings",
                    label: "sidebar.facility_manager_meetings",
                  },
                  {
                    key: "senior_manager_ancillary_service_meetings",
                    label: "sidebar.senior_manager_ancillary_service_meetings",
                  },
                ],
              };
              x.children[x.children.length] = {
                key: "standard_operating_procedures",
                label: "sidebar.standard_operating_procedures",
              };
              x.children[x.children.length] = {
                key: "transition_program",
                label: "sidebar.transition_program",
              };
            }
            if (x.children !== undefined && x.key === "chemical") {
              x.children[x.children.length] = {
                key: "supplier_certificate",
                label: "sidebar.supplier_certificate",
              };
            }
          }
          return x;
        });

        // if (org_id === 1) {
        //   let index = newOption.findIndex((data) => data.key === "report");

        //   newOption.splice(index + 1, 0, {
        //     key: "COVID-19",
        //     label: "sidebar.COVID-19",
        //     leftIcon: "bug",
        //     children: [
        //       {
        //         key: "infection_control_certificate",
        //         label: "sidebar.infection_control_certificate",
        //       },
        //       {
        //         key: "tsa_aged_care_fundamentals",
        //         label: "sidebar.tsa_aged_care_fundamentals",
        //       },
        //       {
        //         key: "covid_training_certificate",
        //         label: "sidebar.covid_training_certificate",
        //       },
        //     ],
        //   });
        // }

        if (role_id == 12) {
          if (superUser_org_id == 1) {
            let index = newOption.findIndex((data) => data.key === "report");

            // newOption.splice(index + 1, 0, {
            //   key: "COVID-19",
            //   label: "sidebar.COVID-19",
            //   leftIcon: "bug",
            //   children: [
            //     {
            //       key: "infection_control_certificate",
            //       label: "sidebar.infection_control_certificate",
            //     },
            //     {
            //       key: "tsa_aged_care_fundamentals",
            //       label: "sidebar.tsa_aged_care_fundamentals",
            //     },
            //     {
            //       key: "covid_training_certificate",
            //       label: "sidebar.covid_training_certificate",
            //     },
            //   ],
            // });
          }
        } else if (role_id == 4) {
          if (is_manager == 1 && org_id == 1) {
            let index = newOption.findIndex((data) => data.key === "report");

            // newOption.splice(index + 1, 0, {
            //   key: "COVID-19",
            //   label: "sidebar.COVID-19",
            //   leftIcon: "bug",
            //   children: [
            //     {
            //       key: "infection_control_certificate",
            //       label: "sidebar.infection_control_certificate",
            //     },
            //     {
            //       key: "tsa_aged_care_fundamentals",
            //       label: "sidebar.tsa_aged_care_fundamentals",
            //     },
            //     {
            //       key: "covid_training_certificate",
            //       label: "sidebar.covid_training_certificate",
            //     },
            //   ],
            // });
          }
        } else if (org_id == 1) {
          let index = newOption.findIndex((data) => data.key === "report");

          // newOption.splice(index + 1, 0, {
          //   key: "COVID-19",
          //   label: "sidebar.COVID-19",
          //   leftIcon: "bug",
          //   children: [
          //     {
          //       key: "infection_control_certificate",
          //       label: "sidebar.infection_control_certificate",
          //     },
          //     {
          //       key: "tsa_aged_care_fundamentals",
          //       label: "sidebar.tsa_aged_care_fundamentals",
          //     },
          //     {
          //       key: "covid_training_certificate",
          //       label: "sidebar.covid_training_certificate",
          //     },
          //   ],
          // });
        } else {
        }

        if (
          org_id === 1 ||
          org_id === 4 ||
          org_id === 5 ||
          org_id === 7 ||
          org_id === 9 ||
          org_id === 10 ||
          org_id === 12 ||
          org_id === 13 ||
          org_id === 14 ||
          org_id === 15 ||
          org_id === 16 ||
          org_id === 18 ||
          org_id === 19 ||
          org_id === 21
        ) {
          newOption.push({
            key: "immunisation-register",
            label: "sidebar.immunisation-register",
            leftIcon: "file-protect",
            children: [
              {
                key: "vaccination_statement",
                label: "sidebar.vaccination_statement",
              },
              {
                key: "covid_vaccine_statement",
                label: "sidebar.covid_vaccine_statement",
              },
              // {
              //   key: 'vaccination_certificates',
              //   label: 'sidebar.vaccination_certificates',
              // }
            ],
          });
        }

        // if (org_id === 1) {
        //   newOption.push({
        //     key: "meeting-minutes",
        //     label: "sidebar.meeting-minutes",
        //     leftIcon: "file-protect",
        //   });
        // }
        if (role_id == 12) {
          if (superUser_org_id == 1) {
            newOption.push({
              key: "meeting-minutes",
              label: "sidebar.meeting-minutes",
              leftIcon: "file-protect",
            });
          }
        } else if (role_id == 4) {
          if (is_manager == 1 && org_id == 1) {
            newOption.push({
              key: "meeting-minutes",
              label: "sidebar.meeting-minutes",
              leftIcon: "file-protect",
            });
          }
        } else if (org_id == 1) {
          newOption.push({
            key: "meeting-minutes",
            label: "sidebar.meeting-minutes",
            leftIcon: "file-protect",
          });
        } else {
        }


        if (org_id === 7) {
          newOption.push({
            key: "periodical-records",
            label: "sidebar.periodical-records",
            leftIcon: "file-protect",
          });
        }

        if((org_id == 1 && role_id == 10) || (org_id == 1 && role_id == 4) || (org_id == 1 && role_id == 11) || (org_id == 1 && role_id == 12 && (login_role_id == 10 || login_role_id == 11))){
            newOption.push({
              key: 'tsa-details',
              label: "sidebar.tsa-details",
              leftIcon: "file-protect",
              // children: this.filterOptions(role_id)
              children: childrenForTSA.filter((item) => item.roleIds.includes(+role_id) )
            })
          }
          if((org_id == 1 && role_id == 10) || (org_id == 1 && role_id == 12 && login_role_id == 10)){
            newOption.push({
              key: 'additional-outbreak-cleaning',
              label: "sidebar.additional-outbreak-cleaning",
              leftIcon: "file-protect",
            })
          }

        if (org_id === 17) {
          newOption.push({
            key: "chemical",
            label: "sidebar.chemical",
            leftIcon: "experiment",
            children: [
              {
                key: "supplier_certificate",
                label: "sidebar.supplier_certificate",
              },
              {
                key: "chemical_register",
                label: "sidebar.chemical_register",
              },
              {
                key: "sds",
                label: "sidebar.sds",
              },
            ],
          });
        }

        self.setState({
          menu_list: newOption.map((data) => {
            if (data.children !== undefined) {
              return {
                ...data,
                children: data.children.sort((a, b) => {
                  return (
                    a.key.toUpperCase().charCodeAt(0) -
                    b.key.toUpperCase().charCodeAt(0)
                  );
                }),
              };
            } else {
              return data;
            }
          }),
        });
      }, 1000);
    } else {
      org_id = parseInt(org_id);
      let newOption = Noptions.filter(function(x, i) {
        // console.log(x ,"call me x mr. x")
        //org_id ===15 15 is SCC organization
        if (
          org_id === 3 ||
          org_id === 5 ||
          org_id === 11 ||
          org_id === 15 ||
          org_id === 19 ||
          org_id === 13
        ) {
          if (x.children !== undefined && x.key === "report") {
            x.children[x.children.length] = {
              key: "KPI_inspection",
              label: "sidebar.KPI_inspection",
            };
          }
        }
        if (
          org_id !== 1
        ) {
          if (x.children !== undefined && x.key === "report") {
            x.children[x.children.length] = {
              key: "per_facility",
              label: "sidebar.periodical",
            };
          }
        }
        if (org_id === 5) {
          if (x.children !== undefined && x.key === "report") {
            x.children[x.children.length] = {
              key: "cleaning_excellence",
              label: "sidebar.cleaning_excellence",
            };
          }
        }
        
        if (org_id === 1) {
          if((role_id == 11 ) || (role_id == 10 ) || (role_id == 12)){
            if (x.children !== undefined && x.key === "report") {
              x.children[x.children.length] = {
                  key: "cleaning excellence",
                  label: "sidebar.cleaning_excellence",
                  children: [
                      {
                          key: "daily_site_summary",
                          label: "sidebar.daily_site_summary",
                      },
                      {
                          key: "unable_to_clean",
                          label: "sidebar.unable_to_clean",
                      },
                      {
                          key: "maintenance",
                          label: "sidebar.maintenance",
                      },
                  ],
              };
            }
          }
        }
        if (org_id === 1) {
          if((role_id == 11 ) || (role_id == 10 ) || (role_id == 12)){
            if (x.children !== undefined && x.key === "report") {
              x.children[x.children.length] = {
                  key: "Periodical",
                  label: "sidebar.periodical",
                  children: [
                      {
                          key: "consolidated",
                          label: "sidebar.consolidated",
                      },
                      {
                          key: "per_facility",
                          label: "sidebar.per_facility",
                      }
                  ],
              };
            }
          }
        }

        if (role_id == 12) {
          if (superUser_org_id == 13) {
            if (x.children !== undefined && x.key === "report") {
              x.children[x.children.length] = {
                key: "air-purifier",
                label: "sidebar.air_purifire",
              };
            }
          }
        } else if (role_id == 4) {
          if (is_manager == 1 && org_id == 13) {
            if (x.children !== undefined && x.key === "report") {
              x.children[x.children.length] = {
                key: "air-purifier",
                label: "sidebar.air_purifire",
              };
            }
          }
        } else if (org_id == 13) {
          if (x.children !== undefined && x.key === "report") {
            x.children[x.children.length] = {
              key: "air-purifier",
              label: "sidebar.air_purifire",
            };
          }
        } else {
        }

        if (org_id === 17) {
          if (x.children !== undefined && x.key === "report") {
            x.children[x.children.length] = {
              key: "equipment_maintenance",
              label: "sidebar.equipment_maintenance",
            };
            x.children[x.children.length] = {
              key: "key_swipe",
              label: "sidebar.key_swipe",
            };
            x.children[x.children.length] = {
              key: "landry_audit",
              label: "sidebar.landry_audit",
            };
            x.children[x.children.length] = {
              key: "KPI_inspection",
              label: "sidebar.KPI_inspection",
            };


          }
        }

        

        if (org_id === 15) {
          if (x.children !== undefined && x.key === "report") {
            x.children.push({
              key: "Surveys",
              label: "sidebar.Surveys",
              children: [
                {
                  key: "cleaning_survey",
                  label: "sidebar.cleaning_survey",
                },
              ],
            });
            x.children[x.children.length] = {
              key: "roll_out_plan",
              label: "sidebar.roll_out_plan",
            };
          }
        }

        if (org_id === 1 || org_id === 19) {
            if (x.children !== undefined && x.key === "report") {
                x.children.push({
                    key: "Surveys",
                    label: "sidebar.Surveys",
                    children: [
                        {
                            key: "cleaning_survey",
                            label: "sidebar.cleaning_survey",
                        },
                        {
                            key: "laundry_survey",
                            label: "sidebar.laundry_survey",
                        },
                    ],
                });
            }
        }

        if (org_id === 1 || org_id === 4 || org_id === 5 || org_id === 18) {
            if (x.children !== undefined && x.key === "report") {
                x.children[x.children.length] = {
                    key: "landry_audit",
                    label: "sidebar.landry_audit",
                };
            }
        }
        if (org_id === 16) {
          if (x.children !== undefined && x.key === "report") {
            // x.children.push({"key":"KPI_inspection" ,"label":"sidebar.KPI_inspection", children: [
            //   {
            //     key: 'executive_KPI_report',
            //     label: 'sidebar.executive_KPI_report',
            //   },
            //   {
            //     key: 'facility_KPI_reports',
            //     label: 'sidebar.facility_KPI_reports',
            //   }]});

            let meetingKey = x.children.findIndex(
              (data) => data.key === "meetings"
            );
            x.children[meetingKey] = {
              key: "meetings",
              label: "sidebar.meetings",
              children: [
                {
                  key: "executive_meetings",
                  label: "sidebar.executive_meetings",
                },
                {
                  key: "facility_manager_meetings",
                  label: "sidebar.facility_manager_meetings",
                },
                {
                  key: "senior_manager_ancillary_service_meetings",
                  label: "sidebar.senior_manager_ancillary_service_meetings",
                },
              ],
            };
            x.children[x.children.length] = {
              key: "standard_operating_procedures",
              label: "sidebar.standard_operating_procedures",
            };
            x.children[x.children.length] = {
              key: "transition_program",
              label: "sidebar.transition_program",
            };
          }
          if (x.children !== undefined && x.key === "chemical") {
            x.children[x.children.length] = {
              key: "supplier_certificate",
              label: "sidebar.supplier_certificate",
            };
          }
        }
        return x;
      });

      if (role_id == 12) {
        if (superUser_org_id == 1) {
          let index = newOption.findIndex((data) => data.key === "report");

          // newOption.splice(index + 1, 0, {
          //   key: "COVID-19",
          //   label: "sidebar.COVID-19",
          //   leftIcon: "bug",
          //   children: [
          //     {
          //       key: "infection_control_certificate",
          //       label: "sidebar.infection_control_certificate",
          //     },
          //     {
          //       key: "tsa_aged_care_fundamentals",
          //       label: "sidebar.tsa_aged_care_fundamentals",
          //     },
          //     {
          //       key: "covid_training_certificate",
          //       label: "sidebar.covid_training_certificate",
          //     },
          //   ],
          // });
        }
      } else if (role_id == 4) {
        if (is_manager == 1 && org_id == 1) {
          let index = newOption.findIndex((data) => data.key === "report");

          // newOption.splice(index + 1, 0, {
          //   key: "COVID-19",
          //   label: "sidebar.COVID-19",
          //   leftIcon: "bug",
          //   children: [
          //     {
          //       key: "infection_control_certificate",
          //       label: "sidebar.infection_control_certificate",
          //     },
          //     {
          //       key: "tsa_aged_care_fundamentals",
          //       label: "sidebar.tsa_aged_care_fundamentals",
          //     },
          //     {
          //       key: "covid_training_certificate",
          //       label: "sidebar.covid_training_certificate",
          //     },
          //   ],
          // });
        }
      } else if (org_id == 1) {
        let index = newOption.findIndex((data) => data.key === "report");

        // newOption.splice(index + 1, 0, {
        //   key: "COVID-19",
        //   label: "sidebar.COVID-19",
        //   leftIcon: "bug",
        //   children: [
        //     {
        //       key: "infection_control_certificate",
        //       label: "sidebar.infection_control_certificate",
        //     },
        //     {
        //       key: "tsa_aged_care_fundamentals",
        //       label: "sidebar.tsa_aged_care_fundamentals",
        //     },
        //     {
        //       key: "covid_training_certificate",
        //       label: "sidebar.covid_training_certificate",
        //     },
        //   ],
        // });
      } else {
      }

      if (
        org_id === 1 ||
        org_id === 4 ||
        org_id === 5 ||
        org_id === 7 ||
        org_id === 9 ||
        org_id === 10 ||
        org_id === 12 ||
        org_id === 13 ||
        org_id === 14 ||
        org_id === 15 ||
        org_id === 16 ||
        org_id === 18 ||
        org_id === 19 ||
        org_id === 21
      ) {
        newOption.push({
          key: "immunisation-register",
          label: "sidebar.immunisation-register",
          leftIcon: "file-protect",
          children: [
            {
              key: "vaccination_statement",
              label: "sidebar.vaccination_statement",
            },
            {
              key: "covid_vaccine_statement",
              label: "sidebar.covid_vaccine_statement",
            },
            // {
            //   key: 'vaccination_certificates',
            //   label: 'sidebar.vaccination_certificates',
            // }
          ],
        });
      }

      // if (org_id === 1) {
      //   newOption.push({
      //     key: "meeting-minutes",
      //     label: "sidebar.meeting-minutes",
      //     leftIcon: "file-protect",
      //   });
      // }

      if (role_id == 12) {
        if (superUser_org_id == 1) {
          newOption.push({
            key: "meeting-minutes",
            label: "sidebar.meeting-minutes",
            leftIcon: "file-protect",
          });
        }
      } else if (role_id == 4) {
        if (is_manager == 1 && org_id == 1) {
          newOption.push({
            key: "meeting-minutes",
            label: "sidebar.meeting-minutes",
            leftIcon: "file-protect",
          });
        }
      } else if (org_id == 1) {
        newOption.push({
          key: "meeting-minutes",
          label: "sidebar.meeting-minutes",
          leftIcon: "file-protect",
        });
      } else {
      }



      if (org_id === 7) {
        newOption.push({
          key: "periodical-records",
          label: "sidebar.periodical-records",
          leftIcon: "file-protect",
        });
        
      }

      if((org_id == 1 && role_id == 10) || (org_id == 1 && role_id == 4) || (org_id == 1 && role_id == 11) || (org_id == 1 && role_id == 12 && (login_role_id == 10 || login_role_id == 11))){
        newOption.push({
          key: 'tsa-details',
          label: "sidebar.tsa-details",
          leftIcon: "file-protect",
          // children: this.filterOptions(role_id) 
          children: childrenForTSA.filter((item) => item.roleIds.includes(+role_id) )
        })
      }

      if((org_id == 1 && role_id == 10) || (org_id == 1 && role_id == 12 && login_role_id == 10)){
        newOption.push({
          key: 'additional-outbreak-cleaning',
          label: "sidebar.additional-outbreak-cleaning",
          leftIcon: "file-protect",
        })
      }

      if (org_id === 17) {
        newOption.push({
          key: "chemical",
          label: "sidebar.chemical",
          leftIcon: "experiment",
          children: [
            {
              key: "supplier_certificate",
              label: "sidebar.supplier_certificate",
            },
            {
              key: "chemical_register",
              label: "sidebar.chemical_register",
            },
            {
              key: "sds",
              label: "sidebar.sds",
            },
          ],
        });
      }

      self.setState({
        menu_list: newOption.map((data) => {
          if (data.children !== undefined) {
            return {
              ...data,
              children: data.children.sort(
                (a, b) =>
                  a.key.toUpperCase().charCodeAt(0) -
                  b.key.toUpperCase().charCodeAt(0)
              ),
            };
          } else {
            return data;
          }
        }),
      });
    }
  }

  handleClick(e) {
    this.props.changeCurrent([e.key]);
    if (this.props.app.view === "MobileView") {
      setTimeout(() => {
        this.props.toggleCollapsed();
        this.props.toggleOpenDrawer();
      }, 100);
    }
  }
  onOpenChange(newOpenKeys) {
    const { app, changeOpenKeys } = this.props;
    const latestOpenKey = newOpenKeys.find(
      (key) => !(app.openKeys.indexOf(key) > -1)
    );
    const latestCloseKey = app.openKeys.find(
      (key) => !(newOpenKeys.indexOf(key) > -1)
    );
    let nextOpenKeys = [];
    if (latestOpenKey) {
      nextOpenKeys = this.getAncestorKeys(latestOpenKey).concat(latestOpenKey);
    }
    if (latestCloseKey) {
      nextOpenKeys = this.getAncestorKeys(latestCloseKey);
    }
    changeOpenKeys(nextOpenKeys);
  }
  getAncestorKeys = (key) => {
    const map = {
      sub3: ["sub2"],
    };
    return map[key] || [];
  };
  getMenuItem = ({ singleOption, menuStyle, subchild }) => {
    const { key, label, children, leftIcon } = singleOption;
    const url = stripTrailingSlash(this.props.url);
    if (children) {
      return (
        <SubMenu
          key={key}
          className={
            key === "report"
              ? "fourth-step"
              : key === "certificates"
              ? "fifth-step"
              : ""
          }
          title={
            <span>
              {subchild !== true ? <Icon type={leftIcon} /> : ""}
              <IntlMessages id={label} />
            </span>
          }
          style={menuStyle}
        >
          {children.map((child) => {
            if (child.children !== undefined && child.children !== null) {
              return this.getMenuItem({
                menuStyle: {},
                singleOption: child,
                subchild: true,
              });
            }
            const linkTo = child.withoutDashboard
              ? `/${child.key}`
              : `${url}/${child.key}`;
            return (
              <Menu.Item key={child.key}>
                <Link to={linkTo}>
                  <IntlMessages id={child.label} />
                </Link>
              </Menu.Item>
            );
          })}
        </SubMenu>
      );
    }
    return (
      <Menu.Item key={key} style={menuStyle}>
        <Link to={`${url}/${key}`}>
          <Icon type={leftIcon} />
          <IntlMessages id={label} />
        </Link>
      </Menu.Item>
    );
  };

  render() {
    const { app } = this.props;
    const menuStyle = {
      border: "unset",
      backgroundColor: "rgb(74, 37, 132)",
    };
    return (
      <Menu
        onClick={this.handleClick}
        style={menuStyle}
        selectedKeys={app.current.length > 0 ? app.current : ["item_0"]}
        mode="horizontal"
        theme={"dark"}
      >
        {this.state.menu_list.map((singleOption) =>
          this.getMenuItem({ singleOption, menuStyle })
        )}
      </Menu>
    );
  }
}

export default connect(
  (state) => ({
    app: state.App,
    height: state.App.height,
  }),
  { toggleOpenDrawer, changeOpenKeys, changeCurrent, toggleCollapsed }
)(TopNav);

// import React, { Component } from 'react';
// import { connect } from 'react-redux';
// import clone from 'clone';
// import { Link } from 'react-router-dom';
// import { Layout } from 'antd';
// import options1 from './options';
// import Menu from '../../components/uielements/menu';
// import IntlMessages from '../../components/utility/intlMessages';
// import SidebarWrapper from './topmenu.style';
// import appActions from '../../redux/app/actions';
// import Logo from '../../components/utility/logo';
// import themes from '../../settings/themes';
// import { themeConfig } from '../../settings';
// // import { func } from 'prop-types';

// class Topmenu extends Component {

//   render() {
//     const { app, toggleOpenDrawer, height } = this.props;
//     const collapsed = clone(app.collapsed) && !clone(app.openDrawer);
//     const { openDrawer } = app;
//     const mode = collapsed === true ? 'vertical' : 'inline';
//     const onMouseEnter = event => {
//       if (openDrawer === false) {
//         toggleOpenDrawer();
//       }
//       return;
//     };
//     const onMouseLeave = () => {
//       if (openDrawer === true) {
//         toggleOpenDrawer();
//       }
//       return;
//     };
//     const customizedTheme = themes[themeConfig.theme];
//     const styling = {
//       backgroundColor: customizedTheme.backgroundColor,
//       // backgroundColor: '#ffffff',
//       color: 'black'

//     };
//     const submenuStyle = {
//       // backgroundColor: 'rgba(0,0,0,0.3)',
//       color: customizedTheme.textColor
//     };
//     const submenuColor = {
//       color: customizedTheme.textColor
//     };
//     return (
//       <div>
//       <SidebarWrapper>
//         <div
//           // trigger={null}
//           // collapsible={true}
//           // collapsed={collapsed}
//           // width={500}
//           // className="isomorphicSidebar"
//           // onMouseEnter={onMouseEnter}
//           // onMouseLeave={onMouseLeave}
//           style={styling}
//         >
//           {/* <Logo collapsed={collapsed} /> */}
//             <Menu
//               onClick={this.handleClick}
//               theme="dark"
//               className="isoDashboardMenu"
//               // mode={mode}
//               mode={'horizontal'}
//               // openKeys={collapsed ? [] : app.openKeys}
//               selectedKeys={app.current}
//               onOpenChange={this.onOpenChange}
//             >
//               {this.state.menu_list.map(singleOption =>

//                 this.getMenuItem({ submenuStyle, submenuColor, singleOption })
//               )}
//             </Menu>
//         </div>
//       </SidebarWrapper>
//       </div>
//     );
//   }
// }
