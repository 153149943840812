import React, { Component } from "react";
import { connect } from "react-redux";
import { Layout, Icon } from "antd";
import appActions from "../../redux/app/actions";
import TopbarNotification from "./topbarNotification";
import TopbarUser from "./topbarUser";
import TopbarWrapper from "./topbar.style";
import themes from "../../settings/themes";
import { themeConfig, siteConfig } from "../../settings";
import TopNav from "../Sidebar/TopNav";
// import IntlMessages from "../../components/utility/intlMessages";
import authAction from "../../redux/auth/actions";
import WebTour from "../../webTour";
import { SetWebTourAction } from "../../actions/actions";
import { store } from "../../redux/store";

const { logout } = authAction;
const { Header } = Layout;
const { toggleCollapsed } = appActions;
const customizedTheme = themes[themeConfig.theme];

class Topbar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showComponent: false,
      user: localStorage.getItem("full_name"),
      logo: localStorage.getItem("client_logo"),
      org_name:
        localStorage.getItem("group") || localStorage.getItem("org_name"),
    };
    this._onButtonClick = this._onButtonClick.bind(this);
    this._onButtonClickFalse = this._onButtonClickFalse.bind(this);
  }
  _onButtonClick() {
    localStorage.setItem("is_first_login", 'true')
    store.dispatch(SetWebTourAction(true))
    return

    console.log("click button");
    this.setState((prev) => {
      return {
        ...prev,
        showComponent: true,
      };
    });
  }

  _onButtonClickFalse() {
    var self = this;
    console.log("abcd");
    self.setState((state) => {
      return {
        ...state,
        showComponent: false,
      };
    });
  }
  componentDidMount() {
    var self = this;
    setTimeout(function() {
      self.setState({
        user: localStorage.getItem("full_name"),
        logo: localStorage.getItem("client_logo"),
        org_name:
          localStorage.getItem("group") || localStorage.getItem("org_name"),
      });
    }, 1000);
  }

  render() {
    const { showComponent } = this.state;
    // const collapsed = this.props.collapsed && !this.props.openDrawer;
    const styling = {
      color: "#ffffff",
      // color: customizedTheme.backgroundColor,
      background: customizedTheme.backgroundColor,
      // background: '#ffffff',
      position: "fixed",
      width: "100%",
      height: "55px",
    };
    const T = new Date();
    const d = T.getDate();
    const m = T.getMonth();
    const y = T.getFullYear().toString();
    const monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    const todayDate = `${d} ${
      monthNames[m].length > 4 ? monthNames[m].substring(0, 3) : monthNames[m]
    } ${y.substring(2)}`;
    return (
      <TopbarWrapper>
        <Header style={styling} className={"isomorphicTopbar collapsed"}>
          <div className="isoLeft">
            <img
              src={siteConfig.siteIconTransparent}
              style={{ width: "140px", maxHeight: "50px" }}
              alt={"Majestic"}
            />
            <TopNav url={this.props.url} />
          </div>
          <ul className="isoRight">
            <li className={"hideMobile"}>
              <Icon
                type="calendar"
                style={{ color: "white", fontSize: "14px" }}
              />
              {"  "}
              {todayDate}
            </li>
            <li className={"hideMobile"}>
              <div className="namesubfeild">
                {<span>{this.state.user}</span>}
              </div>
              <div>
                {<span className="subName">{this.state.org_name}</span>}
              </div>
            </li>
            <li
              onClick={() => this.setState({ selectedItem: "user" })}
              className="isoUser"
            >
              <TopbarUser
                userIconColor={customizedTheme.backgroundColor}
                style={{ border: "1px solid black" }}
              />
            </li>
            <li>
              <Icon
                type="bell"
                style={{ color: "white", fontWeight: "bold" }}
              />
              {/* <TopbarNotification locale={locale} /> */}
            </li>
            
              <li className="ninegth-step">
              {localStorage.getItem("role_id") != "12" ? (
                <Icon
                  onClick={() => this._onButtonClick()}
                  type="question"
                  style={{ color: "white", fontWeight: "bold" }}
                />    ) : (
                  null
                )}
                {/* {this.state.showComponent ? (
                  <WebTour passFunc={this._onButtonClickFalse} showAgain={this.state.showComponent} />
                ) : null} */}
                {/* <TopbarNotification locale={locale} /> */}
              </li>
        

            {/* <li>
                <a className="isoDropdownLink" onClick={this.props.logout} href="# ">
                  <IntlMessages id="topbar.logout" />
                  <Icon type="logout" style={{color:'#ffffff'}} />
                </a>
            </li> */}
          </ul>
        </Header>
      </TopbarWrapper>
    );
  }
}

export default connect(
  (state) => ({
    ...state.App,
    ...state.Common
  }),
  { toggleCollapsed, logout }
)(Topbar);
