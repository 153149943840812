import Axios from "axios";
import { TOKEN } from "../helpers/constants/localstoregeConstants";
import { MYM_WEB } from "../helpers/constants/rbuConstants";
import { getRequest, postRequest } from "../helpers/intersepters/apiUtils";
import Setting from "../settings";
const apiUrl = Setting.apiUrl; // Client Portal API URL
const apiUrl2 = Setting.apiUrl2; // MyM API URL

// const apiUrl = "http://www.facility.dev.asepsiserp.com/api/"
// const apiUrl = "http://localhost:9091/api/"

// var config = require("../../utils/generalconfig.json");
export const RECEIVE_LOGIN = "RECEIVE_LOGIN";
export const CHECK_LOGIN_STATUS = "CHECK_LOGIN_STATUS";
export const CLIENT_DETAIL = "CLIENT_DETAIL";
export const EDUCATION_REPORT = "EDUCATION_REPORT";
export const FACILITY_LIST = "FACILITY_LIST";
export const AUDIT_REPORT = "AUDIT_REPORT";
export const EQUIPMENT_MAINTENANCE_REPORT = "EQUIPMENT_MAINTENANCE_REPORT";
export const EQUIPMENT_MAINTENANCE_REGISTER = "EQUIPMENT_MAINTENANCE_REGISTER";
export const SEND_MAIL = "SEND_MAIL";
export const EDUCATION_CHART = "EDUCATION_CHART";
export const CLEANING_AUDIT_CHART = "CLEANING_AUDIT_CHART";
export const LAUNDRY_AUDIT_CHART = "LAUNDRY_AUDIT_CHART";
export const RISK_ASSESSMENT_CHART = "RISK_ASSESSMENT_CHART";
export const STAFF_CHART = "STAFF_CHART";
export const KPI_INSPECTION_REPORT = "KPI_INSPECTION_REPORT";
export const KEY_SWIPE_REPORT = "KEY_SWIPE_REPORT";
export const IMMUNISATION_REPORT = "IMMUNISATION_REPORT";
export const POLICE_CHECK = "POLICE_CHECK";
export const RECEIVE_FORGOT = "RECEIVE_FORGOT";
export const UPDATE_PASSWORD = "UPDATE_PASSWORD";
export const PERIODICALS = "PERIODICALS";
export const CLEANING_MONTH_AUDIT_REPORT = "CLEANING_MONTH_AUDIT_REPORT";
export const LAUNDRY_MONTH_AUDIT_REPORT = "LAUNDRY_MONTH_AUDIT_REPORT";
export const CERTIFICATES_DATA = "CERTIFICATES_DATA";
export const REGISTERCHEMICAL_DATA = "REGISTERCHEMICAL_DATA";
export const SDSCHEMICAL_DATA = "SDSCHEMICAL_DATA";
export const SUPPLIERCERTIFICATE_DATA = "SUPPLIERCERTIFICATE_DATA";
export const WORKCOVER_CERTIFICATE = "WORKCOVER_CERTIFICATE";
export const PERIODICALS_FACILITY_WISE = "PERIODICALS_FACILITY_WISE";
export const GET_INFECTION_CONTROL = "GET_INFECTION_CONTROL";
export const GET_TSA_CERTIFICATE = "GET_TSA_CERTIFICATE";
export const VACCINATION_CERTIFICATES = "VACCINATION_CERTIFICATES";
export const VACCINATION_STATEMENT = "VACCINATION_STATEMENT";
export const COVID19_TRAINIG_CERTIFICATE = "COVID19_TRAINIG_CERTIFICATE";
export const SERVEY_CLEANING_LAUNDRY = "SERVEY_CLEANING_LAUNDRY";
export const VACCINATION_STATMENT = "VACCINATION_STATMENT";
export const GET_VACCINATION_TYPE = "GET_VACCINATION_TYPE";
export const ROLL_OUT_DATA = "ROLL_OUT_DATA";
export const GET_GROUP_LIST = "GET_GROUP_LIST";
export const GET_MEETING_MINUTES = "GET_MEETING_MINUTES";
export const GET_AIR_PURIFIRE = "GET_AIR_PURIFIRE";
export const GET_FACILITY_LIST = "GET_FACILITY_LIST";
export const GET_SUPER_USER_FACILITY_LIST = "GET_SUPER_USER_FACILITY_LIST";
export const USER_LOG = "USER_LOG";
export const SET_WEB_TOUR = 'SET_WEB_TOUR';
export const FILE_LOADING_STATE = "FILE_LOADING_STATE"

export const SET_FACILITY_WISE_PERIODICAL_DATA = 'SET_FACILITY_WISE_PERIODICAL_DATA'

// JavaDale Periodical Records
export const SET_PERIODICAL_RECORDS = 'SET_PERIODICAL_RECORDS'

// Manager Login
export const SET_MANAGER_ORGANIZATIONS_DATA = "SET_MANAGER_ORGANIZATIONS_DATA";
export const SET_FACILITY_BY_MANAGER = 'SET_FACILITY_BY_MANAGER'

export const SET_STATE_DATA = 'SET_STATE_DATA'

// Upload Certificate
export const SET_CERTIFICATE_DATA = 'SET_CERTIFICATE_DATA'

// Get TSA Certificate
export const TSA_CERTIFICATE = 'TSA_CERTIFICATE'

// Additional Outbreak Cleaning
export const SET_ADDITIONAL_OUTBREAK_DETAILS = 'SET_ADDITIONAL_OUTBREAK_DETAILS'

// Cleaning Excellence
export const SET_ALL_SITE_DETAILS = 'SET_ALL_SITE_DETAILS'
export const SET_SITE_SUMMARY_REPORT = 'SET_SITE_SUMMARY_REPORT'

// Maintenance Report
export const SET_MAINTENANCE_REPORT = 'SET_MAINTENANCE_REPORT'

// Unable to clean Report
export const SET_UNABLE_TO_CLEAN_REPORT = 'SET_UNABLE_TO_CLEAN_REPORT'

// Unable to clean Report
export const GET_GROUP_ELEMENT_AUDIT_SCORE = 'GET_GROUP_ELEMENT_AUDIT_SCORE'
export const GET_FACILITY_ELEMENT_AUDIT_SCORE = 'GET_FACILITY_ELEMENT_AUDIT_SCORE'

// Periodic Records Certificates
export const UPLOAD_PERIODICAL_SELECTED_YEAR = 'UPLOAD_PERIODICAL_SELECTED_YEAR'
export const PERIODICAL_RECORDS_CERTIFICATE = 'PERIODICAL_RECORDS_CERTIFICATE'
export const PERIODICAL_RECORDS_SELECTED_FACILITY = 'PERIODICAL_RECORDS_SELECTED_FACILITY'
export const CONSOLIDATED_PERIODICAL_REPORTS = 'CONSOLIDATED_PERIODICAL_REPORTS'


/*login start */
export function userLogin(post) {
  return (dispatch) => {
    return dispatch(login(post));
  };
}

function login(post) {
  return async (dispatch) => {
    return await postRequest({ url: "login", rbu: '', payload: post })
      .then((response) => {
        if (response.data !== "") {
          if (window) {
            try {
              localStorage.clear();
              localStorage.setItem("profile", 1);
              localStorage.setItem("is_first_login",  response.data.user.user_data.is_first_login);
  
              if(response.data.user.user_data.is_first_login == 'true'){
                dispatch(setWebTour(true))
              }
              
              localStorage.setItem(TOKEN, response.data.token)
              
              localStorage.setItem("email", response.data.user.user_data.email);
              localStorage.setItem(
                "user_id",
                response.data.user.user_data.user_id
              );
              localStorage.setItem(
                "user_role_id",
                response.data.user.user_access.user_role_id
              );
              localStorage.setItem(
                "role_id",
                response.data.user.user_access.role_id
                );
              localStorage.setItem("org_id", response.data.user.user_data.org_id);
              localStorage.setItem(
                "full_name",
                response.data.user.user_data.full_name
                );
                localStorage.setItem(
                  "org_name",
                  response.data.user.user_data.org_name
              );
              let client_logo = response.data.user.user_data.clinet_logo.split(
                "/"
                );
                localStorage.setItem(
                  "client_logo",
                  client_logo[client_logo.length - 1]
                  );
                  localStorage.setItem('is_manager', (response.data.user.user_access.role_id == 4 || response.data.user.user_access.role_id == 12) ? 1 : 0);
              
            } catch (error) {
              console.log('error :>> ', error);
            }

          }
          dispatch(requestLogin(response.data));
        } else {
          dispatch(requestLogin([]));
        }
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.status === 403) {
            //browserHistory.push('/');
            window.location.reload();
          } else {
          }
        }
      });
  };
}

export const SetWebTourAction = (value = false) => {
  return (dispatch) => {
    return dispatch(setWebTour(value))
  }
}

function setWebTour(data) {
  return {
    type: SET_WEB_TOUR,
    set_web_tour: data
  }
}

function requestLogin(response) {
  return {
    type: RECEIVE_LOGIN,
    login_user_data: response,
  };
}

// ====== forgot password

export function userForgot(post) {
  return (dispatch) => {
    return dispatch(forgot(post));
  };
}

function forgot(post) {
  return async (dispatch) => {
    return await postRequest({ url: "forgot", rbu: '', payload: post})
      .then((response) => {
        if (response.data !== "") {
          dispatch(requestForgot(response.data));
          // if (window) {
          //   localStorage.clear();
          //   localStorage.setItem('user_id',response.data.user.user_data.user_id);
          //   localStorage.setItem('user_role_id',response.data.user.user_access.user_role_id);
          //   localStorage.setItem('role_id',response.data.user.user_access.role_id);
          //   localStorage.setItem('org_id',response.data.user.user_data.org_id);
          //   localStorage.setItem('full_name',response.data.user.user_data.full_name);
          //   localStorage.setItem('org_name',response.data.user.user_data.org_name);
          //  let client_logo =  response.data.user.user_data.clinet_logo.split("/");
          //   localStorage.setItem('client_logo',client_logo[client_logo.length-1]);
          // }
        } else {
          //browserHistory.push('/');
          //  window.location('/');
          dispatch(requestForgot([]));
        }
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.status === 403) {
            //browserHistory.push('/');
            window.location.reload();
          } else {
          }
        }
      });
  };
}

function requestForgot(response) {
  return {
    type: RECEIVE_FORGOT,
    forgot_user_data: response,
  };
}

//======================= update password

export function userUpdatePassword(post) {
  return (dispatch) => {
    return dispatch(updatePassword(post));
  };
}

function updatePassword(post) {
  return async (dispatch) => {
    return await postRequest({ url: "update_password", rbu: '', payload: post})
      .then((response) => {
        if (response.data !== "") {
          dispatch(requestUpdatePassword(response.data));
          localStorage.setItem("password", post.password);

          // if (window) {
          //   localStorage.clear();
          //   localStorage.setItem('user_id',response.data.user.user_data.user_id);
          //   localStorage.setItem('user_role_id',response.data.user.user_access.user_role_id);
          //   localStorage.setItem('role_id',response.data.user.user_access.role_id);
          //   localStorage.setItem('org_id',response.data.user.user_data.org_id);
          //   localStorage.setItem('full_name',response.data.user.user_data.full_name);
          //   localStorage.setItem('org_name',response.data.user.user_data.org_name);
          //  let client_logo =  response.data.user.user_data.clinet_logo.split("/");
          //   localStorage.setItem('client_logo',client_logo[client_logo.length-1]);
          // }
        } else {
          //browserHistory.push('/');
          //  window.location('/');
          dispatch(requestUpdatePassword([]));
        }
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.status === 403) {
            //browserHistory.push('/');
            // window.location.reload();
          } else {
          }
        }
      });
  };
}

function requestUpdatePassword(response) {
  return {
    type: UPDATE_PASSWORD,
    update_password: response,
  };
}

//==== check login

function CheckLoginStatusData() {
  return async (dispatch) => {
    return await getRequest({ url: "check_session", rbu: ''})
      .then((response) => {
        if (response.data !== "") {
          dispatch(requestLoginStatus(response.data));
        } else {
          dispatch(requestLoginStatus([]));
        }
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.status === 403) {
            // window.location.href = "/";
            // window.location.reload();
          } else {
          }
        }
      });
  };
}

function requestLoginStatus(response) {
  return {
    type: CHECK_LOGIN_STATUS,
    login_status_data: response,
  };
}

export function CheckLoginStatus() {
  return (dispatch) => {
    return dispatch(CheckLoginStatusData());
  };
}

//=====================Education Report======================//
export function getEducationReportData(post) {
  return (dispatch) => {
    return dispatch(getEducationReport(post));
  };
}

function getEducationReport(post) {
  return async (dispatch) => {
    await postRequest({ url: "education_report", rbu:'', payload: post})
      .then((response) => {
        if (response.data !== "") {
          dispatch(requestEducationReportData(response.data));
        } else {
          dispatch(requestEducationReportData([]));
        }
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.status === 403) {
            // window.location.href = "/";
            // window.location.reload();
          } else {
          }
        }
      });
  };
}

function requestEducationReportData(response) {
  return {
    type: EDUCATION_REPORT,
    education_report_data: response,
  };
}

//=====================Cleaning & Laundry Audit Report for month vise at group level======================//
export function getCleaningAuditReportMonthData(post) {
  return (dispatch) => {
    return dispatch(getCleaningAuditReportMonth(post));
  };
}

function getCleaningAuditReportMonth(post) {
  return async (dispatch) => {
    await postRequest({ url: "monthly_cleaning_audit_report", rbu:'', payload: post})
      .then((response) => {
        // console.log(response,"================================================================= action")
        if (response.data !== "") {
          dispatch(requestCleaningAuditReportMonthData(response.data));
        } else {
          dispatch(requestCleaningAuditReportMonthData([]));
        }
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.status === 403) {
            // window.location.href = "/";
            // window.location.reload();
          } else {
          }
        }
      });
  };
}

function requestCleaningAuditReportMonthData(response) {
  return {
    type: CLEANING_MONTH_AUDIT_REPORT,
    cleaning_audit_report_month_data: response,
  };
}

export function getLaundryAuditReportMonthData(post) {
  return (dispatch) => {
    return dispatch(getLaundryAuditReportMonth(post));
  };
}

function getLaundryAuditReportMonth(post) {
  return async (dispatch) => {
    await postRequest({ url: `monthly_laundry_audit_report`, rbu: '', payload: post})
      .then((response) => {
        // console.log(response,"================================================================= action")
        if (response.data !== "") {
          dispatch(requestLaundryAuditReportMonthData(response.data));
        } else {
          dispatch(requestLaundryAuditReportMonthData([]));
        }
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.status === 403) {
            // window.location.href = "/";
            // window.location.reload();
          } else {
          }
        }
      });
  };
}

function requestLaundryAuditReportMonthData(response) {
  return {
    type: LAUNDRY_MONTH_AUDIT_REPORT,
    laundry_audit_report_month_data: response,
  };
}

//====================================Certificates=========================================//
export function getCertificates(post) {
  return (dispatch) => {
    return dispatch(getCertificaesData(post));
  };
}

function getCertificaesData(post) {
  return async (dispatch) => {
    await postRequest({url: "certificates", rbu: '', payload: post})
      .then((response) => {
        if (response.data !== "") {
          // console.log(response,"================================================================= check true")
          dispatch(requestCertificatesData(response.data));
        } else {
          dispatch(requestCertificatesData([]));
        }
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.status === 403) {
            // window.location.href = "/";
            // window.location.reload();
          } else {
          }
        }
      });
  };
}

function requestCertificatesData(response) {
  return {
    type: CERTIFICATES_DATA,
    certificates_data: response,
  };
}

//====================== Chemical ==========================================//

export function getSdsChemical(post) {
  return (dispatch) => {
    return dispatch(getSdsChemicalData(post));
  };
}

function getSdsChemicalData(post) {
  return async (dispatch) => {
    await postRequest({ url: "sdsChemical",rbu: '', payload: post})
      .then((response) => {
        if (response.data !== "") {
          // console.log(response,"================================================================= check true")
          dispatch(requestSdsChemicalData(response.data));
        } else {
          dispatch(requestSdsChemicalData([]));
        }
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.status === 403) {
            // window.location.href = "/";
            // window.location.reload();
          } else {
          }
        }
      });
  };
}

function requestSdsChemicalData(response) {
  return {
    type: SDSCHEMICAL_DATA,
    sds_chemical_data: response,
  };
}

export function getRegisterChemical(post) {
  return (dispatch) => {
    return dispatch(getRegisterChemicalData(post));
  };
}

function getRegisterChemicalData(post) {
  return async (dispatch) => {
    await postRequest({url: "registerChemical", rbu: '', payload: post})
      .then((response) => {
        if (response.data !== "") {
          // console.log(response,"================================================================= check true")
          dispatch(requestRegisterChemicalData(response.data));
        } else {
          dispatch(requestRegisterChemicalData([]));
        }
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.status === 403) {
            // window.location.href = "/";
            // window.location.reload();
          } else {
          }
        }
      });
  };
}

function requestRegisterChemicalData(response) {
  return {
    type: REGISTERCHEMICAL_DATA,
    register_chemical_data: response,
  };
}

export function getSupplierCertificate(post) {
  return (dispatch) => {
    return dispatch(getSupplierCertificateData(post));
  };
}

function getSupplierCertificateData(post) {
  return async (dispatch) => {
    await postRequest({ url: "supplierCertificate", rbu: '', payload: post})
      .then((response) => {
        if (response.data !== "") {
          // console.log(response,"================================================================= check true")
          dispatch(requestSupplierCertificateData(response.data));
        } else {
          dispatch(requestSupplierCertificateData([]));
        }
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.status === 403) {
            // window.location.href = "/";
            // window.location.reload();
          } else {
          }
        }
      });
  };
}

function requestSupplierCertificateData(response) {
  return {
    type: SUPPLIERCERTIFICATE_DATA,
    supplier_certificate_data: response,
  };
}

//======================== workcover certificate =======================//

export function getWorkcoverCertificate(post) {
  return (dispatch) => {
    return dispatch(getWorkcoverCertificateData(post));
  };
}

function getWorkcoverCertificateData(post) {
  return async (dispatch) => {
    await postRequest({ url: "workCoverCerti", rbu: '', payload: post})

      .then((response) => {
        // console.log(response);
        if (response.data !== "") {
          dispatch(requestWorkcoverCertificateData(response.data));
        } else {
          dispatch(requestWorkcoverCertificateData([]));
        }
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.status === 403) {
            window.location.href = "/";
            // window.location.reload();
          } else {
          }
        }
      });
  };
}

function requestWorkcoverCertificateData(response) {
  return {
    type: WORKCOVER_CERTIFICATE,
    workcover_certificate_data: response,
  };
}

//=====================Cleaning & Laundry Audit Report======================//
export function getAuditReportData(post) {
  return (dispatch) => {
    return dispatch(getAuditReport(post));
  };
}

function getAuditReport(post) {
  return async (dispatch) => {
    await postRequest({ url: "audit_report", rbu: '', payload: post})
      .then((response) => {
        if (response.data !== "") {
          dispatch(requestAuditReportData(response.data));
          //   console.log(response.data,"================================================================= action")
        } else {
          dispatch(requestAuditReportData([]));
        }
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.status === 403) {
            window.location.href = "/";
            // window.location.reload();
          } else {
          }
        }
      });
  };
}

function requestAuditReportData(response) {
  return {
    type: AUDIT_REPORT,
    audit_report_data: response,
  };
}

//=====================Equipment Maintenance Report======================//
export function getEquipmentMaintenanceReportData(post) {
  return (dispatch) => {
    return dispatch(getEquipmentMaintenanceReport(post));
  };
}

function getEquipmentMaintenanceReport(post) {
  return async (dispatch) => {
    await postRequest({url: "equipment_maintenance", rbu: '', payload: post})
      .then((response) => {
        if (response.data !== "") {
          dispatch(requestEquipmentMaintenanceReportData(response.data));
        } else {
          dispatch(requestEquipmentMaintenanceReportData([]));
        }
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.status === 403) {
            window.location.href = "/";
            // window.location.reload();
          } else {
          }
        }
      });
  };
}

function requestEquipmentMaintenanceReportData(response) {
  return {
    type: EQUIPMENT_MAINTENANCE_REPORT,
    equipment_maintenance_report_data: response,
  };
}

//==============================Kpi Inspection ===========================//

export function getKpiInspectionReportData(post) {
  return (dispatch) => {
    return dispatch(getKpiInspectionReport(post));
  };
}

function getKpiInspectionReport(post) {
  return async (dispatch) => {
    await postRequest({ url: "kpi_inspection", rbu: '', payload: post})

      .then((response) => {
        // console.log(response,"=======================================");
        if (response.data !== "") {
          dispatch(requestKpiInspectionReportData(response.data));
        } else {
          dispatch(requestKpiInspectionReportData([]));
        }
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.status === 403) {
            window.location.href = "/";
            // window.location.reload();
          } else {
          }
        }
      });
  };
}

function requestKpiInspectionReportData(response) {
  return {
    type: KPI_INSPECTION_REPORT,
    kpi_inspection_data: response,
  };
}

//============================== Key & Swipe ===========================//

export function getKeySwipeReportData(post) {
  return (dispatch) => {
    return dispatch(getKeySwipeReport(post));
  };
}

function getKeySwipeReport(post) {
  return async (dispatch) => {
    await postRequest({ url: "key_swipe", rbu: '', payload: post})
      .then((response) => {
        if (response.data !== "") {
          dispatch(requestKeySwipeReportData(response.data));
        } else {
          dispatch(requestKeySwipeReportData([]));
        }
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.status === 403) {
            window.location.href = "/";
            // window.location.reload();
          } else {
          }
        }
      });
  };
}

function requestKeySwipeReportData(response) {
  return {
    type: KEY_SWIPE_REPORT,
    key_swipe_data: response,
  };
}

//============================== Immunisation ===========================//

export function getImmunisationReportData(post) {
  return (dispatch) => {
    return dispatch(getImmunisationReport(post));
  };
}

function getImmunisationReport(post) {
  return async (dispatch) => {
    await postRequest({ url: "immunisation",rbu: '', payload: post})
      .then((response) => {
        if (response.data !== "") {
          dispatch(requestImmunisationReportData(response.data));
        } else {
          dispatch(requestImmunisationReportData([]));
        }
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.status === 403) {
            window.location.href = "/";
            // window.location.reload();
          } else {
          }
        }
      });
  };
}

function requestImmunisationReportData(response) {
  return {
    type: IMMUNISATION_REPORT,
    immunisation_data: response,
  };
}

//============================= police check ====================//
export function getPoliceCheckReportData(post) {
  return (dispatch) => {
    return dispatch(getPoliceCheckReport(post));
  };
}

function getPoliceCheckReport(post) {
  return async (dispatch) => {
    await postRequest({ url: "police_check", rbu: '', payload: post})
      .then((response) => {
        if (response.data !== "") {
          dispatch(requestPoliceCheckReportData(response.data));
        } else {
          dispatch(requestPoliceCheckReportData([]));
        }
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.status === 403) {
            window.location.href = "/";
            // window.location.reload();
          } else {
          }
        }
      });
  };
}

function requestPoliceCheckReportData(response) {
  return {
    type: POLICE_CHECK,
    police_check_data: response,
  };
}

// ==========================GET Equipment register===============================

export function getEquipmentRegisterData(post) {
  return (dispatch) => {
    return dispatch(getEquipmentRegister(post));
  };
}

function getEquipmentRegister(post) {
  return async (dispatch) => {
    await postRequest({ url: "equipment_pdf_details_mym", payload: post})
      .then((response) => {
        if (response.data !== "") {
          dispatch(requestEquipmentRegisterData(response.data));
        } else {
          dispatch(requestEquipmentRegisterData([]));
        }
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.status === 403) {
            window.location.href = "/";
            // window.location.reload();
          } else {
          }
        }
      });
  };
}

function requestEquipmentRegisterData(response) {
  return {
    type: EQUIPMENT_MAINTENANCE_REGISTER,
    equipment_register_data: response,
  };
}

//============================Get Facility ===========================//

export function getFacilityList(post) {
  return (dispatch) => {
    return dispatch(getFacilitiesList(post));
  };
}

function getFacilitiesList(post) {

  let is_manager = localStorage.getItem('is_manager') == 1 ? true : false;
  let org_id = localStorage.getItem('org_id')
  let user_id = localStorage.getItem('user_id')


  return async (dispatch) => {

    let promices = is_manager ? getRequest({ url: `get_faclity_details_by_org_id_and_user_id/${org_id}/${user_id}`, payload: post}) : postRequest({ url: "facility_list", rbu: '', payload: post})

    promices.then((response) => {
        if (response.data !== "") {

          if(response.data.facility && Object.keys(response.data.facility).length > 0){
            return dispatch(requestFacilitiesList(response.data));
          } else if(response.data.result && response.data.result.length > 0) {
            return dispatch(requestFacilitiesList(response.data));
          } else {
            return dispatch(requestFacilitiesList({error: "Not found"}));
          }


        } else {
          dispatch(requestFacilitiesList([]));
        }
      })
      promices.catch((error) => {
        if (error.response) {
          if (error.response.status === 403) {
            window.location.href = "/";
            // window.location.reload();
          } else {
          }
        }
      });
  };
}

function requestFacilitiesList(response) {
  return {
    type: FACILITY_LIST,
    facility_list: response,
  };
}

//=============================== send email ====================

export function sendEmail(post, type = "report") {
  return (dispatch) => {
    return dispatch(sendEmailMethod(post, type));
  };
}

function sendEmailMethod(post, type) {
  return async (dispatch) => {
    return await postRequest({ url: "send_email", rbu: '', payload: post})
      .then((response) => {
        if (response.data !== "") {
          // console.log(response,"====email====")
          return dispatch(requestToSendEMail({ ...response.data, type }));
        } else {
          return dispatch(requestToSendEMail([]));
        }
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.status === 403) {
            window.location.href = "/";
            // window.location.reload();
          } else {
          }
        }
      });
  };
}

export function requestToSendEMail(response) {
  return {
    type: SEND_MAIL,
    send_email: response,
  };
}

//============================= perodicals ==============================

export function periodicalsData(post) {
  return (dispatch) => {
    return dispatch(getPeriodicalsData(post));
  };
}

function getPeriodicalsData(post) {
  return async (dispatch) => {
    await postRequest({ url: "periodicals", rbu: '', payload: post})
      .then((response) => {
        if (response.data !== "") {
          dispatch(requestPeriodicalsData(response.data));
        } else {
          dispatch(requestPeriodicalsData([]));
        }
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.status === 403) {
            window.location.href = "/";
            // window.location.reload();
          } else {
          }
        }
      });
  };
}

function requestPeriodicalsData(response) {
  return {
    type: PERIODICALS,
    periodicals: response,
  };
}

export function periodicalsFacilityWiseData(post) {
  return (dispatch) => {
    return dispatch(getPeriodicalsFacilityWiseData(post));
  };
}

function getPeriodicalsFacilityWiseData(post) {
  return async (dispatch) => {
    await postRequest({ url: "periodicals_facility_wise", rbu: '', payload: post})

      .then((response) => {
        if (response.data !== "") {
          dispatch(requestPeriodicalsFacilityWiseData(response.data));
          // console.log(response.data,"================================================================= action")
        } else {
          dispatch(requestPeriodicalsFacilityWiseData([]));
        }
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.status === 403) {
            window.location.href = "/";
            // window.location.reload();
          } else {
          }
        }
      });
  };
}

function requestPeriodicalsFacilityWiseData(response) {
  return {
    type: PERIODICALS_FACILITY_WISE,
    periodicals_facility_wise: response,
  };
}

/*    *     *     *     *     *     *     *    *     *     *     *     *     *     *    *    *     *     *     *     *     *     *

======================================== charts ============================================

*    *     *     *     *     *     *     *      *    *     *     *     *     *     *     *    *     *     *     *     *     *     */

export function getAllChart(post) {
  return (dispatch) => {
    dispatch(getEducationChart(post));
    dispatch(getCleaningAuditChart(post));
    dispatch(getLaundryAuditChart(post));
    dispatch(getRiskAssessmentChart(post));
    dispatch(getStaffChart(post));
    return true;
  };
}

// ============== eduacation =================

function getEducationChart(post) {
  return async (dispatch) => {
    await postRequest({url: "education_count", rbu:'', payload: post})
      .then((response) => {
        // console.log(response , "============================ New Data ==========================")
        if (response.data !== "") {
          dispatch(requestEducationChart(response.data));
        } else {
          dispatch(requestEducationChart([]));
        }
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.status === 403) {
            window.location.href = "/";
            // window.location.reload();
          } else {
          }
        }
      });
  };
}

function requestEducationChart(response) {
  return {
    type: EDUCATION_CHART,
    education_chart: response,
  };
}

//========================== cleaning audit ======================
function getCleaningAuditChart(post) {
  return async (dispatch) => {
    await postRequest({ url: "cleaning_audit_score", rbu: '', payload: post})
      .then((response) => {
        if (response.data !== "") {
          dispatch(requestCleaningAuditChart(response.data));
        } else {
          dispatch(requestCleaningAuditChart([]));
        }
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.status === 403) {
            window.location.href = "/";
            // window.location.reload();
          } else {
          }
        }
      });
  };
}

function requestCleaningAuditChart(response) {
  return {
    type: CLEANING_AUDIT_CHART,
    cleaning_audit_chart: response,
  };
}

//============================= laundry audit ============================
function getLaundryAuditChart(post) {
  return async (dispatch) => {
    await postRequest({ url: `laundry_audit_score`, rbu: '', payload: post})
      .then((response) => {
        if (response.data !== "") {
          dispatch(requestLaundryAuditChart(response.data));
        } else {
          dispatch(requestLaundryAuditChart([]));
        }
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.status === 403) {
            window.location.href = "/";
            // window.location.reload();
          } else {
          }
        }
      });
  };
}

function requestLaundryAuditChart(response) {
  return {
    type: LAUNDRY_AUDIT_CHART,
    laundry_audit_chart: response,
  };
}

//================================ risk assessment ===================

function getRiskAssessmentChart(post) {
  return async (dispatch) => {
    await postRequest({url: "facility_incident", rbu: '', payload: post})
      .then((response) => {
        if (response.data !== "") {
          dispatch(requestRiskAssessmentChart(response.data));
        } else {
          dispatch(requestRiskAssessmentChart([]));
        }
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.status === 403) {
            window.location.href = "/";
            // window.location.reload();
          } else {
          }
        }
      });
  };
}

function requestRiskAssessmentChart(response) {
  return {
    type: RISK_ASSESSMENT_CHART,
    risk_assessment_chart: response,
  };
}

//================================ staff ===================

function getStaffChart(post) {
  return async (dispatch) => {
    await postRequest({url: "employment_type", rbu: '', payload: post})
      .then((response) => {
        if (response.data !== "") {
          dispatch(requestStaffChart(response.data));
        } else {
          dispatch(requestStaffChart([]));
        }
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.status === 403) {
            window.location.href = "/";
            // window.location.reload();
          } else {
          }
        }
      });
  };
}

function requestStaffChart(response) {
  return {
    type: STAFF_CHART,
    staff_chart: response,
  };
}

//=================== covid-19=======================

export function getInfectionControl(post) {
  return (dispatch) => {
    return dispatch(getInfectionControlList(post));
  };
}

function getInfectionControlList(post) {
  return async (dispatch) => {
    await postRequest({ url: "infection_control_certificate_by_facility_id" , payload: post})

      .then((response) => {
        if (response.data !== "") {
          dispatch(requestInfectionControlList(response.data));
        } else {
          dispatch(requestInfectionControlList([]));
        }
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.status === 403) {
            window.location.href = "/";
            // window.location.reload();
          } else {
          }
        }
      });
  };
}

function requestInfectionControlList(response) {
  return {
    type: GET_INFECTION_CONTROL,
    get_infection_control: response,
  };
}

export function getTsaCertificate(post) {
  return (dispatch) => {
    return dispatch(getTsaCertificateList(post));
  };
}

function getTsaCertificateList(post) {
  return async (dispatch) => {
    await postRequest({ url: "tsa_certificate_by_facility_id", payload: post})

      .then((response) => {
        if (response.data !== "") {
          dispatch(requestTsaCertificateList(response.data));
        } else {
          dispatch(requestTsaCertificateList([]));
        }
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.status === 403) {
            window.location.href = "/";
            // window.location.reload();
          } else {
          }
        }
      });
  };
}

function requestTsaCertificateList(response) {
  return {
    type: GET_TSA_CERTIFICATE,
    get_tsa_certificate_list: response,
  };
}

//============ vaccination certificates =============

export function getVaccinationReportData(post) {
  return (dispatch) => {
    return dispatch(getVaccinationReport(post));
  };
}

function getVaccinationReport(post) {
  return async (dispatch) => {
    await postRequest({ url: "vaccination_for_facility_portal",rbu: '', payload: post})
      .then((response) => {
        if (response.data !== "") {
          dispatch(requestgetVaccinationReportData(response.data));
        } else {
          dispatch(requestgetVaccinationReportData([]));
        }
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.status === 403) {
            window.location.href = "/";
            // window.location.reload();
          } else {
          }
        }
      });
  };
}

function requestgetVaccinationReportData(response) {
  return {
    type: VACCINATION_CERTIFICATES,
    vaccination_certificates: response,
  };
}

export function getVaccinationStatementData(post) {
  return (dispatch) => {
    return dispatch(getVaccinationStatement(post));
  };
}

function getVaccinationStatement(post) {
  return async (dispatch) => {
    await postRequest({ url: "vaccination_stmt_for_facility_portal", rbu: '', payload: post})
      .then((response) => {
        if (response.data !== "") {
          dispatch(requestgetVaccinationStatData(response.data));
        } else {
          dispatch(requestgetVaccinationStatData([]));
        }
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.status === 403) {
            window.location.href = "/";
            // window.location.reload();
          } else {
          }
        }
      });
  };
}

function requestgetVaccinationStatData(response) {
  return {
    type: VACCINATION_STATEMENT,
    vaccination_statement: response,
  };
}

export function covid19TrainingCertificate(post) {
  return (dispatch) => {
    return dispatch(covid19TrainingCertificateData(post));
  };
}

function covid19TrainingCertificateData(post) {
  return async (dispatch) => {
    await postRequest({ url: "covid19_training_certificate", rbu: '', payload: post})
      .then((response) => {
        if (response.data !== "") {
          dispatch(requestcovid19TrainingData(response.data));
        } else {
          dispatch(requestcovid19TrainingData([]));
        }
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.status === 403) {
            window.location.href = "/";
            // window.location.reload();
          } else {
          }
        }
      });
  };
}

function requestcovid19TrainingData(response) {
  return {
    type: COVID19_TRAINIG_CERTIFICATE,
    covid19_training_certificate: response,
  };
}

// ================ servey module ================= //

export function ServeyCleaningLaundry(post) {
  return (dispatch) => {
    return dispatch(ServeyCleaningLaundryData(post));
  };
}

function ServeyCleaningLaundryData(post) {
  return async (dispatch) => {
    await postRequest({ url: "surveys_cleaning_laundry", rbu: '', payload: post})
      .then((response) => {
        if (response.data !== "") {
          dispatch(requestServeyCleaningLaundry(response.data));
        } else {
          dispatch(requestServeyCleaningLaundry([]));
        }
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.status === 403) {
            window.location.href = "/";
            // window.location.reload();
          } else {
          }
        }
      });
  };
}

function requestServeyCleaningLaundry(response) {
  return {
    type: SERVEY_CLEANING_LAUNDRY,
    servey_cleaning_servey: response,
  };
}

// ================= end servey module ================ //

// ================ vaccination type ================= //

export function VaccinationType() {
  return (dispatch) => {
    return dispatch(VaccinationTypeData());
  };
}

function VaccinationTypeData() {
  return async (dispatch) => {
    await postRequest({ url: "get_vaccination_types", rbu: ''})
      .then((response) => {
        if (response.data !== "") {
          dispatch(requestVaccinationTypeData(response.data));
        } else {
          dispatch(requestVaccinationTypeData([]));
        }
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.status === 403) {
            window.location.href = "/";
            // window.location.reload();
          } else {
          }
        }
      });
  };
}

function requestVaccinationTypeData(response) {
  return {
    type: GET_VACCINATION_TYPE,
    get_vaccination_type: response,
  };
}

// ================= end vaccination type ================ //

// =================  vaccination stmt ================ //

export function VaccinationStmt(post) {
  return (dispatch) => {
    return dispatch(VaccinationStmtData(post));
  };
}

function VaccinationStmtData(post) {
  return async (dispatch) => {
    await postRequest({ url: "vaccination_stmt", rbu: '', payload: post})
      .then((response) => {
        if (response.data !== "") {
          dispatch(requestServeyVaccinationStmt(response.data));
        } else {
          dispatch(requestServeyVaccinationStmt([]));
        }
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.status === 403) {
            window.location.href = "/";
            // window.location.reload();
          } else {
          }
        }
      });
  };
}

function requestServeyVaccinationStmt(response) {
  return {
    type: VACCINATION_STATMENT,
    vaccination_statment: response,
  };
}

// ================= end vaccination stmt ================ //

// =================  vaccination stmt ================ //

export function RollOutPlan(post) {
  return (dispatch) => {
    return dispatch(RollOutPlanData(post));
  };
}

function RollOutPlanData(post) {
  return async (dispatch) => {
    await postRequest({ url: "roll_out_plan", rbu: '', payload: post})
      .then((response) => {
        if (response.data !== "") {
          dispatch(requestRollOutPlanData(response.data));
        } else {
          dispatch(requestRollOutPlanData([]));
        }
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.status === 403) {
            window.location.href = "/";
            // window.location.reload();
          } else {
          }
        }
      });
  };
}

function requestRollOutPlanData(response) {
  return {
    type: ROLL_OUT_DATA,
    roll_out_data: response,
  };
}

// ================= end vaccination stmt ================ //
//==== Org. Get============= //

function GroupData() {
  return async (dispatch) => {
    return await getRequest({ url: `get_all_organization_list`, rbu: MYM_WEB})
      .then((response) => {
        if (response.data !== "") {
          dispatch(getGroupStatus(response.data));
        } else {
          dispatch(getGroupStatus([]));
        }
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.status === 403) {
            // window.location.href = "/";
            // window.location.reload();
          } else {
          }
        }
      });
  };
}

function getGroupStatus(response) {
  return {
    type: GET_GROUP_LIST,
    group_status_data: response,
  };
}

export function GroupDataStatus() {
  return (dispatch) => {
    return dispatch(GroupData());
  };
}

//==== Facility Get============= //

function FacilityData(id) {
  return async (dispatch) => {
    return await getRequest({ url: `get_all_fac_list_by_org_id/${id}`, rbu : MYM_WEB })
      .then((response) => {
        if (response.data !== "") {
          dispatch(getFacilityStatus(response.data));
        } else {
          dispatch(getFacilityStatus([]));
        }
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.status === 403) {
            // window.location.href = "/";
            // window.location.reload();
          } else {
          }
        }
      });
  };
}

function getFacilityStatus(response) {
  return {
    type: GET_FACILITY_LIST,
    facility_status_data: response,
  };
}

export function FacilityDataStatus(id) {
  return (dispatch) => {
    return dispatch(FacilityData(id));
  };
}

//====super user group & Facility login============= //

function SuperUSerFacilityData(role_id, org_id, role_facility_id, facility_id) {
  return async (dispatch) => {
    return await getRequest(
      { url: `group_facility_list?role_id=${role_id}&org_id=${org_id}&role_facility_id=${role_facility_id}&facility_id=${facility_id}`})
      .then((response) => {
        if (response.data !== "") {
          dispatch(getSuperUserFacilityStatus(response.data));
        } else {
          dispatch(getSuperUserFacilityStatus([]));
        }
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.status === 403) {
            // window.location.href = "/";
            // window.location.reload();
          } else {
          }
        }
      });
  };
}

function getSuperUserFacilityStatus(response) {
  return {
    type: GET_SUPER_USER_FACILITY_LIST,
    super_user_facility_status_data: response,
  };
}

export function SuperUserFacilityDataStatus(
  role_id,
  org_id,
  role_facility_id,
  facility_id
) {
  return (dispatch) => {
    return dispatch(
      SuperUSerFacilityData(role_id, org_id, role_facility_id, facility_id)
    );
  };
}

//==== User Log ============= //

function UserLogData(id) {
  return async (dispatch) => {
    return await postRequest({ url: `user_login_details/${id}`, rbu: MYM_WEB })
      .then((response) => {
        if (response.data !== "") {
          dispatch(getUserLog(response.data));
        } else {
          dispatch(getUserLog([]));
        }
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.status === 403) {
            // window.location.href = "/";
            // window.location.reload();
          } else {
          }
        }
      });
  };
}

function getUserLog(response) {
  return {
    type: USER_LOG,
    user_log: response,
  };
}

export function userLog(id) {
  return (dispatch) => {
    return dispatch(UserLogData(id));
  };
}

//==== Meeting Minutes Get============= //

function MeetingData(data) {
  return async (dispatch) => {
    return await postRequest({ url: "get_meeting_minutes", payload: data})
      .then((response) => {
        if (response.data !== "") {
          dispatch(getMeeingStatus(response.data));
        } else {
          dispatch(getMeeingStatus([]));
        }
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.status === 403) {
          } else {
          }
        }
      });
  };
}

function getMeeingStatus(response) {
  return {
    type: GET_MEETING_MINUTES,
    meeting_minutes_data: response,
  };
}

export function MeetingMinutesStatus(post) {
  return (dispatch) => {
    return dispatch(MeetingData(post));
  };
}

// Manager Login

const getOrganizations = (id) => {
  return async (dispatch) => {
    return await getRequest({ url: `get_organization_by_user_id/${id}`}).then((response) => {
      if(response.data !== ""){
        dispatch(setOrganizationsDataInReducers(response.data.result))
      } else {
        dispatch(setOrganizationsDataInReducers([]))
      }
    }).catch((err) => {})
  }
}

  const setOrganizationsDataInReducers = (data) => { 
    return { 
      type: SET_MANAGER_ORGANIZATIONS_DATA,
      manager_organization_data: data
    }
  }

export function getOrganizationByUserId(id) {
  return (dispatch) => {
    return dispatch(getOrganizations(id))
  }
}

// Get facility by manager

const getFacilityForManager = ({ org_id, user_id }) => {
  return async (dispatch) => {
    return await getRequest({ url: `get_faclity_details_by_org_id_and_user_id/${org_id}/${user_id}`}).then((response) => {
      if(response.data !== ""){
        dispatch(setFacilityDataToReducer(response.data.result))
      } else {
        dispatch(setFacilityDataToReducer([]))
      }
    })
  }
}

const setFacilityDataToReducer = (data) => {
  return { 
    type: SET_FACILITY_BY_MANAGER,
    facility_by_manager: data
  }
}

export const getFacilityForManagerById = ({ org_id, user_id }) => {
  return (dispatch) => {
    return dispatch(getFacilityForManager({ org_id, user_id }))
  }
}


// Facilitieswise periodical year report  


const getFacilityWisePeriodicalData = ({ year, facility_id }) => {
  return async (dispatch) => {
    return await getRequest({ url: `facilitywise_periodical_year_report/${year}/${facility_id}/7` }).then((response) => {
      if(response.data !== ""){
        dispatch(setFacilityWisePeriodicalDataToReducer(response.data.result))
      } else {
        dispatch(setFacilityWisePeriodicalDataToReducer([]))
      }
    })
  }
}


const setFacilityWisePeriodicalDataToReducer = (data) => {
  return { 
    type: SET_FACILITY_WISE_PERIODICAL_DATA,
    periodical_report_data: data
  }
}


export const getFacilityWisePeriodicalDataAction = ({ year, facility_id }) => {
  return (dispatch) => {
    return dispatch(getFacilityWisePeriodicalData({ year, facility_id }))
  }
}


// Periodical Records only for JavaDale Group

const getPeriodicalRecords = (facilityID, year) => {
    return async (dispatch) => {
        return await getRequest(
            { url: `periodical_record/${facilityID}/${year}`}
        ).then((response) => {
            if (Array.isArray(response.data.result)) {
                dispatch(setPeriodicalRecordsToReducer(response.data.result));
            } else {
                dispatch(setPeriodicalRecordsToReducer([]));
            }
        });
    };
};

const setPeriodicalRecordsToReducer = (data) => {
    return {
        type: SET_PERIODICAL_RECORDS,
        periodical_records: data,
    };
};

export const getPeriodicalRecordsAction = (facilityID, year) => {
    return (dispatch) => {
        return dispatch(getPeriodicalRecords(facilityID, year));
    };
};

// Feature AutoLogin

const autoLogin = ({email, token}) => {
  return async (dispatch) => {
    return await postRequest({ url: `client_auto_login?email=${email}&token_id=${token}`, extraParameters: { params: { token } }}).then((response) => {

      // return
      if (response.data !== "") {
        dispatch(requestLogin(response.data.result));
        if (window) {
          localStorage.clear();
          localStorage.setItem("profile", 1);
          localStorage.setItem("is_first_login",  response.data.result.user.user_data.is_first_login);

          if(response.data.result.user.user_data.is_first_login == 'true'){
            dispatch(setWebTour(true))
          }

          localStorage.setItem(TOKEN, response.data.result.token)
          

          localStorage.setItem("email", response.data.result.user.user_data.email);
          localStorage.setItem(
            "user_id",
            response.data.result.user.user_data.user_id
          );
          localStorage.setItem(
            "user_role_id",
            response.data.result.user.user_access.user_role_id
          );
          localStorage.setItem(
            "role_id",
            response.data.result.user.user_access.role_id
          );
          localStorage.setItem("org_id", response.data.result.user.user_data.org_id);
          localStorage.setItem(
            "full_name",
            response.data.result.user.user_data.full_name
          );
          localStorage.setItem(
            "org_name",
            response.data.result.user.user_data.org_name
          );
          let client_logo = response.data.result.user.user_data.client_logo.split(
            "/"
          );
          localStorage.setItem(
            "client_logo",
            client_logo[client_logo.length - 1]
          );

          if(response.data.result.user.manager_access){
            localStorage.setItem('is_manager', response.data.result.user.manager_access.is_manager)
          }
        }
      } else {
        dispatch(requestLogin([]));
      }
    }).catch((error) => {
      console.log(error, "error")

      // return
      window.location.href = "/";
      
    })
  }
}


export const autoLoginAction = ({email, token}) => {
  return dispatch => {
    return dispatch(autoLogin({email, token}))
  }
}

//==== Air Purifire get============= //

function AirPurifireData(data) {
  return async (dispatch) => {
    return await postRequest({ url: "get_air_purifier_schedule",  payload: data})
      .then((response) => {
        if (response.data !== "") {
          dispatch(getAirPurifireStatus(response.data));
        } else {
          dispatch(getAirPurifireStatus([]));
        }
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.status === 403) {
          } else {
          }
        }
      });
  };
}

function getAirPurifireStatus(response) {
  return {
    type: GET_AIR_PURIFIRE,
    air_purifire_data: response,
  };
}

export function AirPurifireStatus(post) {
  return (dispatch) => {
    return dispatch(AirPurifireData(post));
  };
}

export const changeFileLoadingState = (data) => {
  return {
    type: FILE_LOADING_STATE,
    fileLoading: data
  }
}

// Get TSA Certificates

export const getTSACertificateAction = () => {
  return (dispatch) => {
    return dispatch(getTSACertificateActionAPICall())
  }
}

const getTSACertificateActionAPICall = () => {
  return async (dispatch) => {
    return await getRequest({ url:`tsa_details`}).then((response) => {
      if(response.data !== ""){
        console.log(response.data.result, "response.data.result")
        dispatch(setTSADataToReducer(response.data.result))
      } else {
        dispatch(setTSADataToReducer([]))
      }
    })
  }
}

const setTSADataToReducer = (data) => {
  return {
    type: TSA_CERTIFICATE,
    tsa_certificate: data
  }
}

// Get All States

export const getAllStatesAction = () => {
  return (dispatch) => {
    return dispatch(getAllStatesAPICall())
  }
}

const getAllStatesAPICall = () => {
  return async (dispatch) => {
    return await getRequest( { url: `get_all_statelist`}).then((response) => {
      if(response.data !== ""){
        dispatch(setStateDataToReducer(response.data.result))
      } else {
        dispatch(setStateDataToReducer([]))
      }
    })
  }
}

const setStateDataToReducer = (data) => {
  return {
    type: SET_STATE_DATA,
    state_data: data
  }
}

// Upload certificate

export const uploadCertificateAction = (data) => {
  return (dispatch) => {
    return dispatch(uploadCertificateAPICall(data))
  }
}

const uploadCertificateAPICall = (data) => {
  return async (dispatch) => {
    return await postRequest({ url: `upload_certificate`, payload: data}).then((response) => {
      if(response.data !== ""){
        dispatch(setCertificateToReducer(response.data.result))
      } else {
        dispatch(setCertificateToReducer([]))
      }
    })
  }
}

const setCertificateToReducer = (data) => {
  return {
    type: SET_CERTIFICATE_DATA,
    certificate_data: data
  }
}

// Additional Outbreak Cleaning

export const AdditionalOutbreakServiceAction = () => {
  return (dispatch) => {
    return dispatch(AdditionalOutbreakServiceAPICall())
  }
}

const AdditionalOutbreakServiceAPICall = () => {
  return async (dispatch) => {
    return await getRequest({url: `additional_outbreak_details` }).then((response) => {
      if(response.data !== ""){
        dispatch(setAdditionalOutbreakServiceDataToReducer(response.data.result))
      } else {
        dispatch(setAdditionalOutbreakServiceDataToReducer([]))
      }
    })
  }
}

const setAdditionalOutbreakServiceDataToReducer = (data) => {
  return {
    type: SET_ADDITIONAL_OUTBREAK_DETAILS,
    aoc_details: data
  }
}

// Cleaning Excellence Menu Actions

export const getAllSiteDetails = (id) => {
  return (dispatch) => {
    return dispatch(getAllSiteDetailsAction(id))
  }
}

const getAllSiteDetailsAction = (id) => {
  return async (dispatch) => {
    return await getRequest({ url: `get_all_site_details/${id}` }).then((response) => {
      if(response.data !== ""){
        dispatch(setAllSiteDetailsToReducer(response.data.result))
      } else{
        dispatch(setAllSiteDetailsToReducer([]))
      }
    })
  }
}

const setAllSiteDetailsToReducer = (data) => {
  return {
    type: SET_ALL_SITE_DETAILS,
    all_site_details: data
  }
}

// Get Site Summary Details
export const getSiteSummaryReport = (body) => {
  return (dispatch) => {
    return dispatch(getSiteSummaryReportAction(body))
  }
}

const getSiteSummaryReportAction = (body) => {
  return async (dispatch) => {
    return await postRequest({ url: 'get_site_summary_report', payload: body }).then((response) => {
      if(response.data !== ""){
        dispatch(setSiteSummaryReportToReducer(response.data.result))
      } else{
        dispatch(setSiteSummaryReportToReducer([]))
      }
    });
  }
}

const setSiteSummaryReportToReducer = (data) => {
  return {
    type: SET_SITE_SUMMARY_REPORT,
    daily_site_summary_report: data
  }
}

// Get Maintenance Report
export const getMaintenanceReport = (body) => {
  return (dispatch) => {
    return dispatch(getMaintenanceReportAction(body))
  }
}

const getMaintenanceReportAction = (body) => {
  return async (dispatch) => {
    return await postRequest({ url: 'get_maintenance_report', payload: body }).then((response) => {
      if(response.data !== ""){
        dispatch(setMaintenanceReportToReducer([response.data]))
      } else{
        dispatch(setMaintenanceReportToReducer([response.data]))
      }
    });
  }
}

const setMaintenanceReportToReducer = (data) => {
  return {
    type: SET_MAINTENANCE_REPORT,
    maintenance_report: data
  }
}

// Get Unable to Clean Report
export const getUnAbleToCleanReport = (body) => {
  return (dispatch) => {
    return dispatch(getUnAbleToCleanReportAction(body))
  }
}

const getUnAbleToCleanReportAction = (body) => {
  return async (dispatch) => {
    return await postRequest({ url: 'get_unable_to_clean_report', payload: body }).then((response) => {
      if(response.data !== ""){
        dispatch(setUnAbleToCleanReportToReducer([response.data]))
      } else{
        dispatch(setUnAbleToCleanReportToReducer([response.data]))
      }
    })
  }
}

export const setUnAbleToCleanReportToReducer = (data) => {
  return {
    type: SET_UNABLE_TO_CLEAN_REPORT,
    unableToClean_report: data
  }
}

// Element Audit Average Score
export const getElementAuditScore = ({body, forWhich}) => {
  return dispatch => {
    return dispatch(getElementAuditScoreAction(body, forWhich))
  }
}

const getElementAuditScoreAction = (body, forWhich) => {
  return async (dispatch) => {
    return await postRequest({ url: 'get_element_avg_score', payload: body }).then((response) => {
      if(response.data !== ""){
        if(forWhich === 'facility' ){
          return dispatch(setFacilityElementAuditScoreToReducer([response.data]))
        } else {
          return dispatch(setGroupElementAuditScoreToReducer([response.data]))
        }
      } else{
        if(forWhich === 'facility' ){
          return dispatch(setFacilityElementAuditScoreToReducer([response.data]))
        } else {
          return dispatch(setGroupElementAuditScoreToReducer([response.data]))
        }
      }
    })
  }
}

export const setFacilityElementAuditScoreToReducer = (data) => {
  return {
    type: GET_FACILITY_ELEMENT_AUDIT_SCORE,
    facilityElementAuditScore: data
  }
}

export const setGroupElementAuditScoreToReducer = (data) => {
  return {
    type: GET_GROUP_ELEMENT_AUDIT_SCORE,
    groupElementAuditScore: data
  }
}

// Upload Periodical Records Certificate

export const uploadPeriodicalSelectedYear = (data) => {
    return {
        type: UPLOAD_PERIODICAL_SELECTED_YEAR,
        upload_periodical_selected_year: data,
    };
};

export const PeriodicalRecordsSelectedFacility = (data) => {
    return {
        type: PERIODICAL_RECORDS_SELECTED_FACILITY,
        periodical_records_selected_facility: data,
    };
};

export const uploadPeriodicalRecord = (data) => {
  return dispatch => {
    return dispatch(uploadPeriodicalRecordAction(data))
  }
}

const uploadPeriodicalRecordAction = (data) => {
  return async dispatch => {
    return await postRequest({ url: 'periodical_record', payload: data }).then((response) => {
      if(response.data !== ""){
        return dispatch(periodicalRecordsCertificate([response.data]))
      } else{
        return dispatch(periodicalRecordsCertificate([]))
      }
    })
  }
}
const periodicalRecordsCertificate = (data) => {
  return {
    type: PERIODICAL_RECORDS_CERTIFICATE,
    periodical_records_data: data
  }
}

export const getConsolidatedPeriodicalRecords = (data) => {
  return dispatch => {
    return dispatch(getConsolidatedPeriodicalAction(data))
  }
}

const getConsolidatedPeriodicalAction = (data) => {
  return async dispatch => {
    //! Make API call to get actual consolidated periodical reports
    // return await postRequest({ url: 'consolidated_periodical_record', rbu: MYM_WEB, payload: data }).then((response) => {
    //   if(response.data !== ""){
    //     return dispatch(getConsolidatedPeriodical([response.data]))
    //   } else{
    //     return dispatch(getConsolidatedPeriodical([]))
    //   }
    // })

    return dispatch(getConsolidatedPeriodical(
      {
        "id": 4,
        "org_id": 1,
        "pdf_link": "https://majestic-prod.s3.ap-southeast-2.amazonaws.com/periodical_reports/Periodicals+2024.pdf",
        "org_name": "Salvation Army Aged Care Plus",
        "meeting_date": "2022-04-01",
        "created_date": "2022-04-19 13:58:22"
    },
    ))
  }
}
const getConsolidatedPeriodical = (data) => {
  return {
    type: CONSOLIDATED_PERIODICAL_REPORTS,
    consolidated_periodical_record_data: data
  }
}

