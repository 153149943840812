import React, { Component } from "react";
import { connect } from "react-redux";
import Popover from "../../components/uielements/popover";
import IntlMessages from "../../components/utility/intlMessages";
import { Icon } from "antd";
// import userpic from '../../image/user1.png';
import authAction from "../../redux/auth/actions";
import TopbarDropdownWrapper from "./topbarDropdown.style";

const { logout } = authAction;

class TopbarUser extends Component {
  constructor(props) {
    super(props);
    this.handleVisibleChange = this.handleVisibleChange.bind(this);
    this.hide = this.hide.bind(this);
    this.state = {
      visible: false,
    };
  }
  hide() {
    this.setState({ visible: false });
  }
  handleVisibleChange() {
    this.setState({ visible: !this.state.visible });
  }
  render() {
    const content = (
      <TopbarDropdownWrapper className="isoUserDropdown" >
        {/* <a className="isoDropdownLink" href="# ">
          <IntlMessages id="themeSwitcher.settings" />
        </a>
        <a className="isoDropdownLink" href="# ">
          <IntlMessages id="sidebar.feedback" />
        </a>
        <a className="isoDropdownLink" href="# ">
          <IntlMessages id="topbar.help" />
        </a> */}
        {localStorage.getItem("profile") != 1 && (
          <a className="isoDropdownLink" href="/dashboard/my_profile">
            My Profile
          </a>
        )}

        {/* <a className="isoDropdownLink" href="/dashboard/contact_us">
          Contact Us
        </a> */}

        <a className="isoDropdownLink" onClick={this.props.logout} href="# ">
          <IntlMessages id="topbar.logout" />
        </a>
      </TopbarDropdownWrapper>
    );

    return (
      <Popover
        content={content}
        trigger="click"
        visible={this.state.visible}
        onVisibleChange={this.handleVisibleChange}
        arrowPointAtCenter={true}
        placement="bottomLeft"
        
      >
        {/* <div className="isoImgWrapper">
          <img alt="user" src={userpic} />
          <i className="ion-android-contact" style={{color:this.props.userIconColor}}></i>
          <span className="userActivity online" />
        </div> */}
        <div className="sixth-step">
          {/* <Icon type="bars" style={{color:'white'}}/>
           */}
          <Icon type="more" style={{ color: "white", fontWeight: "bold" }} />
        </div>
      </Popover>
    );
  }
}
export default connect(
  null,
  { logout }
)(TopbarUser);
