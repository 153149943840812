import Auth from './auth/reducer';
import App from './app/reducer';
import Common from '../reducers/common_reducers';
import Contacts from './contacts/reducer'
// import Calender from './calendar/reducer'
import Calender from './periodicals/reducer'
export default {
  Auth,
  App,
Common,
Contacts,
Calender
};
