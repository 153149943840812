import React from 'react';
import CKEditor from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
// import ClassicEditor from '@ckeditor/ckeditor5-editor-classic';
import LayoutContentWrapper from '../../components/utility/layoutWrapper';
import { sendEmail } from '../../actions/actions';
import LayoutContent from '../../components/utility/layoutContent';
import { connect } from 'react-redux';
import {
    Form,
    Input,
    notification,
    Icon,
    Select,
    Modal,
    Button,
  } from 'antd';
  import './style.css';
import { sendNotification } from '../../helpers/utility';
  



class ContactBotton extends React.Component {
    state = {
        confirmDirty: false,
        autoCompleteResult: [],
        message:'',
        loading:false,
        visible: false ,
        error:'',
        username: {
          value: localStorage.getItem('user'),
        },
      };
     
      showModal = () => {
        this.setState({
          visible: true,
        });
      };


      handleOk = e => {
        console.log(e);
        this.setState({
          visible: false,
        });
      };
    
      handleCancel = e => {
        console.log(e);
        this.setState({
          visible: false,
        });
      };
    

      handleSubmit = e => {
        e.preventDefault();
        if(this.state.message === ''){
          this.setState({error:'Please input your Message!'})
        }
       
        this.props.form.validateFields((err, values) => {
          if (!err) {
            let{message} = this.state;
            values= {
              ...values,
              message
            }
            let org_name =`${localStorage.getItem('org_name')} - `
            let NameT = `<h3>Name - </h3>`
            let emailT = `<h3>Email - </h3>`
            let messageT = `<h3>Message - </h3>`
            let UserName = `<p>${values.name}</p> `
            let fromBody =  `<p>Contact us enquiry from facility portal user ${localStorage.getItem('email')}</p> `
            var Email_data = {
              "email_list": 'admin@majesticservicesgroup.com.au',
              "attachments":'',
              "html_body":  NameT + UserName + emailT + fromBody + messageT + this.state.message,
              "subject": 'Facility Portal - ' + org_name + values.subject
            };
            this.props.dispatch(sendEmail(Email_data,"contact"));
            // console.log('Received values of form: ', Email_data);
          }
        });
      };

      componentDidUpdate(prevProps, prevState) {
        if (prevProps.send_email !== this.props.send_email) {
          if(this.props.send_email.result === true && this.props.send_email.type === 'contact'){
            this.props.form.resetFields();
            this.setState({visible:false,message:''});
            sendNotification({type: 'success', message: 'Thanks! Email has been sent successfully.'})
              return false
          }
        }
      }
    
      onChange  = e => {
        this.setState({
          message:e,
          error:''
        });
      } ;
    
    

  render() {
   
    const { getFieldDecorator } = this.props.form;
    
    return (
     
      <div >
       
          <Button className="seventh-step" type="primary" onClick={this.showModal} style={{transform: 'rotate(-90deg)', zIndex: '99999', letterSpacing: '1px',borderTopLeftRadius:'4px',borderTopRightRadius:'4px'}}>
            {this.props.buttonName}
          </Button>
         
          <Modal
            title="Contact Us"
            visible={this.state.visible}
            onOk={this.handleSubmit}
            onCancel={this.handleCancel}
            okText="Submit"
            cancelButtonProps={{ style: { display: 'none' } }}
          >
            <Form>
              <Form.Item>
            
                {getFieldDecorator('name', {initialValue: `${localStorage.getItem('full_name')}`} , {
                  rules: [{ required: true, message: 'Please input your name!' }],
                })(
                  <Input
                    prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />}
                    placeholder="Your Name" 
                  />,
                )}
              </Form.Item>
              <Form.Item>
                {getFieldDecorator('subject', {
                    rules: [
                    {
                        required: true,
                        message: 'Please input your Subject!',
                    },
                    ],
                })(<Input prefix={<Icon type="question-circle" style={{ color: 'rgba(0,0,0,.25)' }} />}
                placeholder="Subject"/>)}
              </Form.Item>
              <Form.Item>
                <CKEditor 
                  // data=''
                  
                    editor={ ClassicEditor }
                    config={{
                     
                      // toolbar: ['Heading', '|', 'Bold', 'Italic', 'Alignment', 'Link', 'List', 'ListUI', 'BlockQuote', 'Undo', 'Redo'],
                      removePlugins: ['Image', 'ImageCaption', 'ImageStyle', 'ImageToolbar', 'ImageUpload']
                    }}
                    data = {this.state.message}
                    onInit={ editor => {
                        // You can store the "editor" and use when it is needed.
                        console.log( 'Editor is ready to use!', editor );
                    } }
                    onChange={ ( event, editor ) => {
                        const data = editor.getData();
                        this.onChange(data)
                    } }
                    onBlur={ ( event, editor ) => {
                        console.log( 'Blur.', editor );
                    } }
                    onFocus={ ( event, editor ) => {
                        console.log( 'Focus.', editor );
                    } }
                    
                />
              <div style={{color: '#f5222d'}}>{this.state.error}</div>
              </Form.Item>
            </Form>
          </Modal>
        </div>
    );
  }
}

ContactBotton = Form.create()(ContactBotton);

function mapStateToProps(state) {
  const { Common } = state;
  // const {
  //   login_user_data: login_user_data ,isLoggedIn :isLoggedIn ,  login_status_data : login_status_data,education_report_data : education_report_data,facility_list : facility_list
  // } = Common;

  const {
      send_email
  } = Common;
  return {
      send_email
  };
}


const WrappedNormalLoginForm = connect(mapStateToProps)(ContactBotton);
export default WrappedNormalLoginForm;

                